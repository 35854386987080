import { useState, useEffect } from "react"

const useScrollHandler = (ref) => {
    const isBrowser = typeof window !== 'undefined'

    // setting initial value to true
    const [scroll, setScroll] = useState(true);

    // running on mount
    useEffect(() => {
        if (!isBrowser) {
            return false;
        }

        const onScroll = () => {
            const refHeight = ref.current ? ref.current.clientHeight : 0;

            const scrollCheck = window.scrollY < refHeight;
            if (scrollCheck !== scroll) {
                setScroll(scrollCheck);
            }
        }

        // setting the event handler from web API
        document.addEventListener(`scroll`, onScroll);

        // cleaning up from the web API
        // run once the component is loaded - componentDidMount()
        return () => {
            // run after the component is removed - componentWillUnmount()
            document.removeEventListener(`scroll`, onScroll);
        }
    }, [scroll, setScroll]);

    return scroll;
}

export default useScrollHandler;