// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"

// s4n
import ImageUtils from './../../utils/gatsby/image'



/**
 * This component renders as Gatsby Image image from ./src/images - local file system
 * 
 * @param {*} props 
 *    src - relative to images directory, example: "custom/logo.svg"
 */
const ComponentStateless = (props) => {

    const { src: relativePath } = props;
    const maxWidth = props.maxWidth || null;

    // delete all props except src
    const attributes = {...props};
    delete attributes['src'];

    const img = ImageUtils.getGatsbyImageDataByRelativePath(relativePath);

    if (!img) {
      return null;
    }

    const GatsbyImage = <Img 
      fluid={img} 
      {...attributes}
    />

    if (maxWidth) {
      return (
        <div style={{ maxWidth }}>
          { GatsbyImage }
        </div>
      )
    }
    
    return GatsbyImage;
}

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default ComponentStateless
