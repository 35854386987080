import React from 'react';
import { connect } from 'react-redux'
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@material-ui/core';
import withDrupalOauthConsumer from './../../../drupal-oauth/withDrupalOauthConsumer';

// s4n
import { injectIntl, Link, navigate } from "gatsby-plugin-intl"
// import LogoutLink from './../LogoutLink';
import axios from 'axios';



class Login extends React.Component {
    state = {
        open: false,
        processing: false,
        username: '',
        password: '',
        error: null,
    };

    constructor(props) {
        super(props);

        this.t = this.props.intl.formatMessage;
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    }

    handleCancel = () => {
        this.setState({ open: false });
    }

    handleSubmit = async (event) => {
        // event.preventDefault();
        this.setState({ processing: true });
        const { username, password } = this.state;

        try {
            const response = await this.props.drupalOauthClient.handleLogin(username, password, '');
            // await this.props.drupalOauthClient.handleLogin(username, password, '');

            // console.log(response)

            if (typeof response === `undefined`) {
                throw new Error({
                    message: this.t({ id: "soft4net_form_login_form_error" }),
                });
            }

            // @see: https://www.drupal.org/project/simple_oauth/issues/3143106
            // await this.loginDrupalUser(username, password);

            // we were authenticated because no exception were thrown
            this.setState({ processing: false, open: false });
            this.props.updateAuthenticatedUserState(true);

        } catch (err) {

            // console.log(err)

            this.setState({
                processing: false,
                // error: err.message,
                error: {
                    message: this.t({ id: "soft4net_form_login_form_error" }),
                },
            });
        }
    }



    // @see: https://www.drupal.org/project/simple_oauth/issues/3143106
    loginDrupalUser = async (username, password) => {
        const data = { name: username, pass: password }
        const options = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, withCredentials: true }
        
        try {
            return await axios.post(`${process.env.SOFT4NET_SOURCE_BASE_URL}/user/login?_format=json`, data, options);
        } catch (e) {
            return e;
        }
    }



    render() {
        const isUserAuthenticated = this.props.userAuthenticated;
        // console.log('isUserAuthenticated: ', isUserAuthenticated);
    
        // const _token = this.props.drupalOauthClient.isLoggedIn();
        // console.log('_token: ', _token);

        // Redirect after login right away to profile page
        if (isUserAuthenticated) {
            navigate(`/user/profile`);
        }

        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h2>Zaloguj się</h2>
                            <br />
                            <TextField
                                error={this.state.error}
                                helperText={this.state.error && this.state.error.message}
                                
                                autoFocus
                                margin="dense"
                                name="username"
                                label={ this.t({ id: "soft4net_form_login_form_username" }) }
                                type="text"
                                fullWidth
                                onChange={event => this.setState({ [event.target.name]: event.target.value })}
                            />
                            <TextField
                                error={this.state.error}
                                helperText={this.state.error && this.state.error.message}

                                margin="dense"
                                name="password"
                                label={ this.t({ id: "soft4net_form_login_form_password" }) }
                                type="password"
                                fullWidth
                                onChange={event => this.setState({ [event.target.name]: event.target.value })}
                            />

                            <p>
                                <Link to={`/user/password/reset`} className={``}>{ this.t({ id: "soft4net_form_login_form_password_reset" }) }</Link>
                            </p>

                            {/* <Button onClick={this.handleCancel} color="default">{ this.t({ id: "soft4net_form_action_cancel" }) }</Button> */}
                            {this.state.processing ? <CircularProgress /> : <Button onClick={(event) => {this.handleSubmit(event)}} color="primary" variant="contained">Log in</Button>}
                        </div>                        
                        <div className="col-md-6">
                            <h2>Nie masz konta?</h2>
                            <br />
                            Dlaczego warto mieć konto w decolly.pl<br />
                            zamawiaj szybciej<br />
                            sprawdzaj poprzednie zamówienia<br />
                            korzystaj z rabatów i promocji
                        </div>                        
                    </div>
                </div>

                {false && 
                    <React.Fragment>
                        <Button onClick={this.handleClickOpen} variant="contained" color="primary">Log in</Button>
                        <Dialog
                            open={this.state.open}
                            onClose={this.handleClose}
                            aria-labelledby="form-dialog-title"
                        >
                            <DialogTitle id="form-dialog-title">Log in</DialogTitle>
                            <DialogContent>
                                {this.state.error && <p className="error">{this.state.error.message}</p>}
                                <DialogContentText>
                                    { this.t({ id: "soft4net_form_login_form_title" }) }
                                </DialogContentText>
                                <TextField
                                    error={this.state.error}
                                    helperText={this.state.error && this.state.error.message}
                                    
                                    autoFocus
                                    margin="dense"
                                    name="username"
                                    label={ this.t({ id: "soft4net_form_login_form_username" }) }
                                    type="text"
                                    fullWidth
                                    onChange={event => this.setState({ [event.target.name]: event.target.value })}
                                />
                                <TextField
                                    error={this.state.error}
                                    helperText={this.state.error && this.state.error.message}

                                    margin="dense"
                                    name="password"
                                    label={ this.t({ id: "soft4net_form_login_form_password" }) }
                                    type="password"
                                    fullWidth
                                    onChange={event => this.setState({ [event.target.name]: event.target.value })}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleCancel} color="default">{ this.t({ id: "soft4net_form_action_cancel" }) }</Button>
                                {this.state.processing ? <CircularProgress /> : <Button onClick={(event) => {this.handleSubmit(event)}} color="primary" variant="contained">Log in</Button>}
                            </DialogActions>
                        </Dialog>
                    </React.Fragment>
                }

            </React.Fragment>
        );
    }
}

const mapStateToProps = null;
const mapDispatchToProps = null;
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withDrupalOauthConsumer(Login)));