// @see: https://github.com/wiziple/gatsby-plugin-intl/blob/master/examples/gatsby-starter-default-intl/src/components/language.js
/*
import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"

const languageName = {
    pl: "Polski",
    en: "English",
    // ko: "한국어",
    de: "Deutsch",
}

const LanguageSwitcher = () => {
  return (
    <div>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages.map(language => (
            <a
              key={language}
              onClick={() => changeLocale(language)}
              style={{
                color: currentLocale === language ? `yellow` : `white`,
                margin: 10,
                textDecoration: `underline`,
                cursor: `pointer`,
              }}
            >
              {languageName[language]}
            </a>
          ))
        }
      </IntlContextConsumer>
    </div>
  )
}

export default LanguageSwitcher
*/



/**
 * @see: https://raw.githubusercontent.com/krashnamusika/krashna-site/master/src/components/Header/LanguageSwitcher.js
 */
// import classNames from 'classnames'
import React, { useEffect, useContext } from 'react'
import { Helmet } from 'react-helmet'
import { changeLocale, useIntl, IntlContextConsumer } from 'gatsby-plugin-intl'

import MenuUtils from "./../../../../components/s4n/Menu/utils"

// s4n
import FlagPL from 'flag-icon-css/flags/4x3/pl.svg'
import FlagEN from 'flag-icon-css/flags/4x3/gb.svg'
import FlagDE from 'flag-icon-css/flags/4x3/de.svg'

// import { connect } from 'react-redux'
// import { intlPreserve } from './../../../../s4n/state/actions/intl'



// @todo: use env variable SOFT4NET_AVAILABLE_LANGUAGES=pl,en 
const languageNames = [
  { code: 'pl', label: 'Polski', flag: FlagPL },
  { code: 'en', label: 'English', flag: FlagEN },
  { code: 'de', label: 'Deutsch', flag: FlagDE },
]

const LanguageSwitcher = props => {
  const intl = useIntl();
  const t = intl.formatMessage;

  // useEffect(() => {
  //     componentDidMount();
  // }, [props.intl]);
  // const componentDidMount = () => {
  //   props.intlPreserve(intl);
  // }

  // const intlContextConsumer = useContext(IntlContextConsumer);
  // const {
  //   defaultLanguage, // "pl"
  //   language: currentLocale, // "pl"
  //   languages, // (2) ["pl", "en"]
  //   // messages, // {siteMetadata_title: "Pokoje w Starej Karczmie", siteMetadata_slogan: "Pokoje w Starej Karczmie", siteMetadata_description: "Z dala od miejskiego zgiełku, kilka kroków od nadw…ańskich polan leży zabytkowa Karczma z XVI wieku.", siteMetadata_author: "", siteMetadata_keywords: "", …}
  //   // originalPath, // "/"
  //   // redirect, // true
  //   // routed, // true
  // } = intlContextConsumer;

  const currentLocale = intl.locale;

  // console.log(intlContextConsumer)

  // getImageByCode(code) {
  //   return `/images/flags/24/${code.toUpperCase()}.png`;
  // }

  const currentMenuItemTranslations = MenuUtils.getCurrentMenuItemTranslations();

  const renderLanguageChoice = (code, label, flag, currentLocale) => {
    let activeClass = currentLocale === code ? 'active' : null;

    /**
     * We need to change to '/' if there's not translation available 
     * because if we set to null changeLocale() will just replace langcode in URL, that might go to unexisting route!!!
     */
    const currentMenuItemTranslationsRoute = currentMenuItemTranslations && currentMenuItemTranslations[code] ? currentMenuItemTranslations[code].link.url : '/';

    // console.log(currentMenuItemTranslationsRoute)

    return (
        <a tabIndex="0" href="#" onClick={() => changeLocale(code, currentMenuItemTranslationsRoute)} className={`list-item ${activeClass ? activeClass : ``}`} key={code} hrefLang={code} title={label}>
          {/* <img src={this.getImageByCode(code)} alt={label} /> */}
          {/* <Img fluid={data.placeholderImage.childImageSharp.fluid} /> */}
          {/* <img src={flag} alt={label} style={{ border: '1px solid #ccc', maxWidth: 30 }} /> */}
          {/* <span className="label">{label}</span> */}
          {/* <span className="icon" style={{backgroundImage: `url('${flag}')`}}></span> */}
          <span className="abbreviation">{code.toUpperCase()}</span>
          <span className="sr-only">{label}</span>
        </a>
    )
  }

  const currentLanguage = languageNames.filter(({ code }) => currentLocale == code)[0];
  const languageNamesLen = languageNames.length;

  return (
    <React.Fragment>
      <Helmet
        defer={false} // @see: https://www.gatsbyjs.com/plugins/gatsby-plugin-react-helmet/?=#titles-dont-appear-when-opening-in-the-background-while-using-gatsby-plugin-offline
      >
        <html lang={currentLocale} />
      </Helmet>
      <div className="language listitem">
        {/* <div className="dropdown-menu" aria-labelledby="langDropdown"> */}
          {languageNames.map((l, index) => {
            return (
              <>
                {renderLanguageChoice(l.code, l.label, l.flag, currentLocale)}
                {languageNamesLen === index + 1 ? `` : `/`}
              </>
            )
          })}
        {/* </div> */}
      </div>
    </React.Fragment>
  )
}

export default React.memo(LanguageSwitcher)

// const mapStateToProps = state => ({
//   intl: state.intl.intl,
// });
// const mapDispatchToProps = dispatch => ({
//   intlPreserve: (intl) => dispatch(intlPreserve(intl)),
// })
// export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher);