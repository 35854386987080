import _ from 'lodash'
import useAllFile from './../../hooks/useAllFile' // Files from ./src/images as local
import useAllFileFile from './../../hooks/useAllFileFile' // Remote files as local
import useAllMediaImage from './../../hooks/useAllMediaImage' // Drupal Media Images, this "more" store metadata for Drupal files, it "wrap" Drupal files

class Image {
    static images = null;

    // constructor() {
    //     this.buildImagesCollection();
    // }

    /**
     * Prepare images collection with uuid keys
     * @see: https://www.gatsbyjs.org/packages/gatsby-image/
     * @param {string} type fluid|fixed
     */
    static buildImagesCollection(type = 'fluid') {
        this.images = {};
        
        const useAllFileData = useAllFile();
        useAllFileData.allFile.edges.forEach(({ node }) => {
            const childImageSharp = node.relativePath && node.childImageSharp && node.childImageSharp[type] ? node.childImageSharp : null;
            
            if (childImageSharp) {
                this.images[node.relativePath] = {
                    [type]: childImageSharp[type],
                };
            }
        });
        
        const useAllFileFileData = useAllFileFile();
        useAllFileFileData.allFileFile.edges.forEach(({ node }) => {
            const childImageSharp = node.localFile && node.localFile.childImageSharp && node.localFile.childImageSharp[type] ? node.localFile.childImageSharp : null;

            if (childImageSharp) {
                this.images[node.drupal_id] = {
                    [type]: childImageSharp[type],
                };
            }
        });

        // console.log(useAllFileData)
        // console.log(useAllFileFileData)
        // console.log(this.images);
    }

    /**
     * @see: https://www.gatsbyjs.org/packages/gatsby-image/
     */
    static getGatsbyImageData = () => {
        if (_.isNull(this.images)) {
            this.buildImagesCollection();
        }

        return this.images;
    }

    /**
     * @see: https://www.gatsbyjs.org/packages/gatsby-image/
     * @param {string} relativePath relative path to images directory, example: "custom/logo.svg"
     * @param {string} type fluid|fixed
     */
    static getGatsbyImageDataByRelativePath = (relativePath, type = 'fluid') => {
        if (_.isNull(this.images)) {
            this.buildImagesCollection();
        }

        return typeof this.images[relativePath] !== `undefined` ? this.images[relativePath][type] : null;
    }

    /**
     * @see: https://www.gatsbyjs.org/packages/gatsby-image/
     * @param {string} uuid Drupal internal image UUID
     * @param {string} type fluid|fixed
     */
    static getGatsbyImageDataByUuid = (uuid, nodePage, type = 'fluid') => {
        if (_.isNull(this.images)) {
            this.buildImagesCollection();
        }

        if (typeof this.images[uuid] !== `undefined`) {
            return  this.images[uuid][type];
        }


        


        
        // console.log(uuid)
        // console.log(nodePage)

        const nodeFileFile = nodePage && nodePage.related_collection_fileFileNodes ? nodePage.related_collection_fileFileNodes.filter(nodeFileFile => nodeFileFile.drupal_id === uuid)[0] : null;
        // console.log(nodeFileFile)

        if (!nodeFileFile) {
            return null;
        }

        const childImageSharp = nodeFileFile.localFile && nodeFileFile.localFile.childImageSharp && nodeFileFile.localFile.childImageSharp[type] ? nodeFileFile.localFile.childImageSharp : null;

        if (childImageSharp) {
            // console.log(childImageSharp[type])
            return childImageSharp[type];
        }

        return null;
    }

    /**
     * @see: https://www.gatsbyjs.org/packages/gatsby-image/
     * @param {string} uuid Drupal internal image UUID
     * @param {string} type fluid|fixed
     */
    static getDrupalMediaImageDataByUuid = (uuid, locale, nodePage) => {
        const useAllMediaImageData = useAllMediaImage();
        let oMediaImage = null;
        
        useAllMediaImageData.allMediaImage.edges.forEach(({ node }) => {
            const nodeRelationshipsFieldMediaImageDrupalId = 
                node.relationships && node.relationships.field_media_image && node.relationships.field_media_image.drupal_id ? node.relationships.field_media_image.drupal_id : null;

            if (uuid == nodeRelationshipsFieldMediaImageDrupalId) {
                oMediaImage = node;
            }
        });

        // returns array with 2 elements!!!! WHY??!!
        // oMediaImage = useAllMediaImageData.allMediaImage.edges.filter(({ node }) => {
        //     return uuid === node.relationships.field_media_image.drupal_id;
        // });

        // console.log(oMediaImage);

        if (oMediaImage) {
            return oMediaImage.field_media_image;
        }






        // @todo: check locale???
        // console.log(nodePage)

        const nodeMediaImage = nodePage && nodePage.related_collection_mediaImageNodes ? nodePage.related_collection_mediaImageNodes.filter(nodeMediaImage => nodeMediaImage.relationships.field_media_image.drupal_id === uuid)[0] : null;

        // console.log(nodeMediaImage)

        if (nodeMediaImage) {
            return nodeMediaImage.field_media_image;
        }

        return null;
    }
}

export default Image;