import React, { useState, useEffect } from 'react';
import { useIntl } from "gatsby-plugin-intl"

// s4n
import $ from 'jquery'
// import loadScript from 'simple-load-script';
// @see: https://github.com/gatsbyjs/gatsby/issues/8755#issuecomment-456766798
import { withPrefix } from 'gatsby'
import useVersion from './../../../hooks/useVersion'



const ComponentStateless = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const currentBuildVersion = useVersion();

    /**
     * @see: https://reactjs.org/docs/hooks-effect.html
     * Same as componentDidMount() hook because we pass an empty array [] as second argument,
     * this argument watches for changes in passed arguments
     * In componentDidMount we have access to window object elsewhere not always!!!
     */
    useEffect(() => {

        const isBrowser = typeof window !== 'undefined'
        if (!isBrowser) {
            return false
        }

        loadScripts();
    }, []);
    // }, [intl.locale]);

    const enableGoogleMaps = (GMaps) => {
        const styles = [
            {
                stylers: [
                    {hue: '#00ffe6'},
                    {saturation: -100},
                ]
            },
            {
                featureType: 'road',
                elementType: 'geometry',
                stylers: [
                    {lightness: -8},
                    {gamma: 1.18},
                    {visibility: 'simplified'},
                ]
            }
        ];

        // https://www.google.com/maps/place/Cegielniana+4A,+30-404+Krak%C3%B3w/@50.0284114,19.9331043,17z/data=!4m5!3m4!1s0x47165ca3b74ab023:0x41ccdeceb39098ca!8m2!3d50.028408!4d19.935293?shorturl=1

        const map = new GMaps({
            div: '#map',
            lat: Number(process.env.GOOGLE_MAPS_LAT),
            lng: Number(process.env.GOOGLE_MAPS_LNG),
            zoom: 16,
            scrollwheel: false,
            draggable: $(document).width() > 736 ? true : false,
        });

        map.addStyle({
            styledMapName: 'Styled Map',
            styles: styles,
            mapTypeId: 'map_style',
        });

        map.setStyle('map_style');

        map.drawOverlay({
            lat: map.getCenter().lat(),
            lng: map.getCenter().lng(),
            content: '<div class="overlay"><img src="/static/soft4net/image/custom/logo.svg" alt="Soft4Net" /><div class="overlay_arrow above"></div></div>',
            verticalAlign:'top',
            horizontalAlign:'center',
        });
    }

    const loadScripts = async () => {





        // const simpleLoadScript = (function (root, factory) {
        //     if (typeof define === 'function' && define.amd) {
        //       define([], factory);
        //     } else if (typeof module === 'object' && module.exports) {
        //       module.exports = factory();
        //     } else {
        //       root.simpleLoadScript = factory();
        //     }
        //   }(this, function () {
        //     var undef;
          
        //     function deleteFromGlobal(name) {
        //       try {
        //         delete window[name];
        //       } catch (e) {
        //         window[name] = null;
        //       }
        //     }
          
        //     function getScript(url, options) {
        //       return new Promise(function (resolve, reject) {
        //         if (typeof url === 'object') {
        //           options = url;
        //           url = options.url;
        //         }
        //         if (!options) options = {};
        //         if (!url) {
        //           reject('Error: no script url');
        //           return;
        //         }
        //         var script = document.createElement('script');
        //         var where = (function () {
        //           if (options.insertInto) {
        //             return document.querySelector(options.insertInto);
        //           }
        //           return options.inBody ? document.body : document.head;
        //         }());
        //         if (!where) {
        //           reject('Error: no DOM element to append script');
        //           return;
        //         }
        //         var attrs = options.attrs;
        //         var removeScript = options.removeScript;
        //         var callBackName = options.callBackName;
        //         for (var attr in attrs) {
        //           if (Object.prototype.hasOwnProperty.call(attrs, attr)) {
        //             script.setAttribute(attr, attrs[attr]);
        //           }
        //         }
        //         if (!callBackName) {
        //           script.addEventListener('load', function () {
        //             if (removeScript) where.removeChild(script);
        //             resolve(removeScript ? undef : script);
        //           });
        //         } else {
        //           window[callBackName] = function (res) {
        //             if (!res) res = removeScript ? undef : script;
        //             if (!options.dontRemoveCallBack) deleteFromGlobal(callBackName);
        //             if (removeScript) where.removeChild(script);
        //             resolve(res || removeScript ? undef : script);
        //           };
        //         }
        //         script.addEventListener('error', function () {
        //           where.removeChild(script);
        //           reject('Error: loading script');
        //         });
        //         script.src = url;
        //         where.appendChild(script);
        //       });
        //     }
          
        //     // array of urls or array of objects
        //     function all() {
        //       if (!arguments.length) return Promise.reject(new Error('No files or no file configs'));
        //       return Promise.all(Array.prototype.slice.call(arguments).map(function (e) {
        //         return Array.isArray(e) ? getScript.apply(null, e) : getScript(e);
        //       }));
        //     }
          
        //     getScript.deleteFromGlobal = deleteFromGlobal;
        //     getScript.all = all;
          
        //     return getScript;
        //   }));
          
    
            // const loadScript = simpleLoadScript();
            const loadScript = function getScript(url, options) {
              return new Promise(function (resolve, reject) {
                if (typeof url === 'object') {
                  options = url;
                  url = options.url;
                }
                if (!options) options = {};
                if (!url) {
                  reject('Error: no script url');
                  return;
                }
                var script = document.createElement('script');
                var where = (function () {
                  if (options.insertInto) {
                    return document.querySelector(options.insertInto);
                  }
                  return options.inBody ? document.body : document.head;
                }());
                if (!where) {
                  reject('Error: no DOM element to append script');
                  return;
                }
                var attrs = options.attrs;
                var removeScript = options.removeScript;
                var callBackName = options.callBackName;
                for (var attr in attrs) {
                  if (Object.prototype.hasOwnProperty.call(attrs, attr)) {
                    script.setAttribute(attr, attrs[attr]);
                  }
                }
                if (!callBackName) {
                  script.addEventListener('load', function () {
                    if (removeScript) where.removeChild(script);
                    resolve(removeScript ? undef : script);
                  });
                } else {
                  window[callBackName] = function (res) {
                    if (!res) res = removeScript ? undef : script;
                    if (!options.dontRemoveCallBack) deleteFromGlobal(callBackName);
                    if (removeScript) where.removeChild(script);
                    resolve(res || removeScript ? undef : script);
                  };
                }
                script.addEventListener('error', function () {
                  where.removeChild(script);
                  reject('Error: loading script');
                });
                script.src = url;
                where.appendChild(script);
              });
            }





        // Google Map START
        const GOOGLE_MAPS_KEY = `AIzaSyAw2j4v8vzGeIt0p1u5R1SwVdsNmviz1_0`;
        await loadScript(`//maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_KEY}&sensor=false`, { inBody: true });
        await loadScript(withPrefix(`/google-map/gmaps.min.js${currentBuildVersion}`), { inBody: true });
        // await loadScript(withPrefix('/google-map/GoogleMaps.js' + currentBuildVersion), { inBody: true });
        // Google Map STOP
        
        if (typeof window.GMaps !== `undefined`) {
            enableGoogleMaps(window.GMaps);
        }
    }

    return (
        <React.Fragment>
            <section className="map">
                {/* <h5 className="hide">Google Maps</h5> */}
                <div className="container-fluid">
                    <div className="row">
                        <div id="map"></div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default ComponentStateless;