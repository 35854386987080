// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from "react"

// s4n
import GeneralUtils from './../utils/common'
import { useIntl } from "gatsby-plugin-intl"
import Link from './s4n/Link' // extends Link from gatsby-plugin-intl
import Menu from "./s4n/Menu/menu"
import MenuUtils from "./../components/s4n/Menu/utils"
import LanguageSwitcher from './../components/s4n/LanguageSwitcher'
import Search from './../components/forms/Search';
// import ImageS4n from "./s4n/image"
//import cssModule from "./../assets/sass/components/Header.module.scss"

import Logo from './../images/custom/logo.svg'
// import LogoWhite from './../images/custom/logo-white-48.png'

// import { Navbar, Nav, NavDropdown } from 'react-bootstrap'

import withDrupalOauthConsumer from '../components/drupal-oauth/withDrupalOauthConsumer';
import Login from './s4n/AccountManagement/Login';
import LogoutLink from './s4n/AccountManagement/LogoutLink';

// import Accessibility from './s4n/Accessibility'
import PromotedFrontPage from './s4n/Promoted/FrontPage'
import Accessibility from './s4n/Accessibility'

import IconFacebook from './../images/custom/icons/facebook.svg'
import IconInstagram from './../images/custom/icons/instagram.svg'
import IconPinterest from './../images/custom/icons/pinterest.svg'
// import IconSearch from './../images/custom/icons/search.svg'
import IconUserAccount from './../images/custom/icons/user-account.svg'
import IconUserWishlist from './../images/custom/icons/user-wishlist.svg'
// import IconCart from './../images/custom/icons/cart.svg'



// Shop START
import CartFlyout from './../components/s4n/Shop/ext/blocks/CartFlyout'
import MenuMegaNav from "./s4n/Menu/Templates/MegaNav"
import MenuMegaSubmenu from "./s4n/Menu/Templates/MegaSubmenu"
// Shop STOP






import classNames from 'classnames';
// import { connect } from 'react-redux'
// import { bindOnClickOnKeyDown } from './s4n/Accessibility'
import $ from "jquery"



import useScrollHandler from './../hooks/LayoutUtilities/useScrollHandler'

import UseDrupalBlockContentBasic from './../hooks/drupal/blockContentBasic'
import { globalHistory } from "@reach/router"
import { connect } from 'react-redux'



// ES6
//import Webform from 'hn-react-webform';

const Header = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { nodeId, siteTitle, userAuthenticated: isUserAuthenticated } = props;

    // console.log(props.intl.locale)
    // console.log('props.userAuthenticated: ', props.userAuthenticated)
    // const sEmailAddress = `info@soft4net.io`;

    const [linkLogoProps, setLinkLogoProps] = useState({});
    
    useEffect(() => {
      const isBrowser = typeof window !== 'undefined';
      if (!isBrowser) {
        return;
      }

      let _linkLogoProps = {};
      if (nodeId === Number(process.env.SOFT4NET_HOME_PAGE_DRUPAL_ID)) {
        _linkLogoProps.onClick = () => window.scrollTo(0, 0);
      } else {
        _linkLogoProps.to = `/`;
      }
      setLinkLogoProps(_linkLogoProps);



/*
      FB.getLoginStatus((response) => {
console.log(response);
        // statusChangeCallback(response);
      });
*/



    }, []);

    const injectContentToMenuId = {

      // before "O nas"
      [`before-entity-node-34`]: <React.Fragment>
        <li className={`nav-item ${Number(nodeId) === Number(process.env.SOFT4NET_HOME_PAGE_DRUPAL_ID) ? `active` : ``}`}>
          <Link to={`/`} className={`nav-link`}>Home</Link>
        </li>
      </React.Fragment>,

      // [`entity-node-16`]: <React.Fragment>
      // 	<li className="dropdown-item go-to-parent-item">
      // 		<Link to={16} prefix={t({ id: `soft4net_go_to_parent_item` })} className="nav-link"></Link>
      // 	</li>
      // 	<li className="dropdown-item">
      // 		<a tabIndex="0" className="nav-link" href="tel:+48797567617">( +48 ) 797 567 617</a>
      // 	</li>
      // 	<li className="dropdown-item">
      // 		<a tabIndex="0" className="nav-link" data-encoded={GeneralUtils.rot13(sEmailAddress)} href="mailto:">{GeneralUtils.rot13(sEmailAddress)}</a>
      // 	</li>
      // 	{/* @todo: add social media */}
      // 	{/* <div className="text px-3"></div> */}
      // </React.Fragment>,

      [`entity-node-34`]: <React.Fragment>
        {/* <Menu type="footer" className="menu-footer" nodeLinkUriRoot="entity:node/14" /> */}
        {/* <Link to={14} className="btn btn-secondary" role="button"></Link> */}
        {/* <PromotedFrontPage limit={4} disableHeader={true} returnOnlyLink={true} anchorClass={`nav-link`} /> */}
        {/* <PromotedFrontPage limit={5} disableWrapp={true} CustomWrapper={props => <li className="dropdown-item">{props.children}</li>} returnOnlyLink={true} anchorClass={`nav-link`} /> */}
        <li className={`dropdown-item go-to-parent-item`}>
          <Link to={Number(process.env.SOFT4NET_SHOP_PAGE_DRUPAL_ID)} prefix={t({ id: `soft4net_go_to_parent_item` })} className={`nav-link`}></Link>
        </li>

        <li className={`dropdown-item ${Number(process.env.SOFT4NET_SHOP_PAGE_DRUPAL_ID) === nodeId ? `active` : ``}`}><Link to={Number(process.env.SOFT4NET_SHOP_PAGE_DRUPAL_ID)} className={`nav-link`}>Wszystkie</Link></li>
        <li className={`dropdown-item ${globalHistory.location.pathname === `/pl/wianki/pory-roku` ? `active` : ``}`}><Link to={`/wianki/pory-roku`} className={`nav-link`}>Pory roku</Link></li>
        <li className={`dropdown-item ${globalHistory.location.pathname === `/pl/wianki/swieta` ? `active` : ``}`}><Link to={`/wianki/swieta`} className={`nav-link`}>Święta</Link></li>
        <li className={`dropdown-item ${globalHistory.location.pathname === `/pl/wianki/okolicznosciowe` ? `active` : ``}`}><Link to={`/wianki/okolicznosciowe`} className={`nav-link`}>Okolicznościowe</Link></li>
        {/* <div className="dropdown-divider"></div> */}
        <li className={`dropdown-item ${globalHistory.location.pathname === `/pl/wianki/promocje` ? `active` : ``}`}><Link to={`/wianki/promocje`} className={`nav-link text-red`}>Promocje</Link></li>
        <li className={`dropdown-item ${globalHistory.location.pathname === `/pl/wianki/nowosci` ? `active` : ``}`}><Link to={`/wianki/nowosci`} className={`nav-link text-red`}>Nowości</Link></li>
        <li className={`dropdown-item ${29 === nodeId ? `active` : ``}`}><Link to={29} className={`nav-link`} /></li>
        <li className={`dropdown-item ${30 === nodeId ? `active` : ``}`}><Link to={30} className={`nav-link`} /></li>
        <li className={`dropdown-item ${35 === nodeId ? `active` : ``}`}><Link to={35} className={`nav-link`} /></li>
        {/* <div className="dropdown-divider"></div> */}
        <li className={`dropdown-item ${globalHistory.location.pathname === `/pl/wianki/opakowania-ozdobne` ? `active` : ``}`}><Link to={`/wianki/opakowania-ozdobne`} className={`nav-link`}>Opakowania ozdobne</Link></li>

      </React.Fragment>,

      // [`entity-node-31`]: <React.Fragment>
      // 	{/* <Menu type="footer" className="menu-footer" nodeLinkUriRoot="entity:node/14" /> */}
      // 	{/* <Link to={14} className="btn btn-secondary" role="button"></Link> */}
      // 	{/* <PromotedFrontPage limit={4} disableHeader={true} returnOnlyLink={true} anchorClass={`nav-link`} /> */}
      // 	<li className="dropdown-item go-to-parent-item">
      // 		<Link to={`/blog`} prefix={t({ id: `soft4net_go_to_parent_item` })} className="nav-link">Blog</Link>
      // 	</li>
      // 	<PromotedFrontPage limit={5} disableWrapp={true} CustomWrapper={props => <li className="dropdown-item">{props.children}</li>} returnOnlyLink={true} anchorClass={`nav-link`} />
      // </React.Fragment>,
      
      // [`before-entity-node-31`]: <React.Fragment>
      // 	<MenuMegaSubmenu />
      // </React.Fragment>,

	  }

  




// SMOOTH_SCROLL STICKY HEADER PROCESSOR START
/*
	  const { scrollbarRef } = props;

    // @see: https://stackoverflow.com/questions/44612364/toggle-class-based-on-scroll-react-js
    // @see: https://stackoverflow.com/questions/35153599/reactjs-get-height-of-an-element

    const headerRef = useRef(null);
    // const HEADER_STICKY_MENU_CLASS = `stuck`;
    // const HEADER_STICKY_MENU_CLASS = `fixed-top`;
    const HEADER_STICKY_MENU_CLASS = `alt`;
    // const [scroll, setScroll] = useState(false);



    const getMenuHeight = () => {       
      // const headerHeight = $('header.header').outerHeight();
      return headerRef.current ? headerRef.current.clientHeight : 0;
    }

    // @see: https://github.com/idiotWu/smooth-scrollbar/blob/1a9227951d62a2e508e71056fc94750e755fd3b3/docs/api.md#scrollbarscrollintoview
    // @see: https://github.com/idiotWu/smooth-scrollbar/issues/97
    const scrollTo = (anchorHash, event = null) => {
      const { scrollbar } = scrollbarRef.current;
      // const $target = $(anchorHash);
      const target = document.getElementById(anchorHash.split(`#`)[1]);

      // if ($target.length) {
      if (target) {
        event && event.preventDefault();

        // Scroll behind standard header to enable alternative header with different height!
        const menuHeight = getMenuHeight();
        if (scrollbar.offset.y < getMenuHeight()) {
        // if (scrollbar.offset.y < menuHeight) {
          // scrollbar.scrollTop = getMenuHeight() + 1; // Scroll 1px behind
          scrollbar.scrollTop = getMenuHeight() * 2; // Scroll 2 times behind standard header to be sure it will have different height for alternative mode!
          // scrollbar.scrollTop = menuHeight * 2; // Scroll 1px behind
        }

        // const offsetY = $target.offset().top - getMenuHeight();
        // const offsetY = target.offsetTop;

        // scrollbar.scrollTo(0, offsetY, 500);
        // scrollbar.scrollTop = offsetY; // Gets or sets scrollbar.offset.y
        // scrollbar.setPosition(0, offsetY); // Likes the window.scrollTo() method, you can use this method to set the scrollbar to the given offset without easing
        // @see: https://github.com/idiotWu/smooth-scrollbar/blob/develop/docs/api.md#scrollbarscrollintoview
        scrollbar.scrollIntoView(
          // $target[0], 
          target,
          {
          offsetTop: getMenuHeight() // we must calculate dynamically!
        });

        // setTimeout(() => {
        //   const $li = $(this).parent();
        //   $li.siblings().removeClass(`active`);
        //   $li.addClass(`active`);
        // }, 1000);

        return true;
      }

      return false;
    }

    const goToClickedAnchorId = function(event) {
        const anchorHash = $(this).prop('hash');
        // console.log(anchorHash)
        // anchorHash === #section-id
        if (anchorHash == '') {
          return true;
        }

        scrollTo(anchorHash, event);

        return true;
    }



    // useLayoutEffect(() => {
    //   if (headerRef.current) {
    //       const height = headerRef.current.clientHeight
    //       // dispatch({type:'GRID_WIDGET_HEIGHT', widget:name, height})
    //   }
    // });

    useEffect(() => {

      const { scrollbar } = scrollbarRef.current;
      if (!scrollbarRef || !scrollbarRef.current) {
        return false;
      }



      // console.log(contextScrollableNodes.sliders)



      $(`a.goto-scroll, .goto-scroll a`).on(`click`, goToClickedAnchorId);

      const urlHash = window.location.hash;
      if (document.getElementById(urlHash.split(`#`)[1])) {
        scrollTo(window.location.hash);
        // setTimeout(() => {
        //   scrollTo(window.location.hash);
        // }, 1000);
      }


      
      // @see: http://jsbin.com/tuqafof/edit?html,css,js,output
      // const header = document.getElementById(`header-main`);
      const header = headerRef.current;
      // console.log(header)
      let offsetY = getMenuHeight();
      // console.log(offsetY)


      // const $body = $(`body`);
      const $menuMain = $('nav#menu-main');
      // const $menuMainLi = $menuMain.find(`ul.navbar-nav.menu-type-main li`).siblings();
      const $menuMainLi = $menuMain.find(`ul.navbar-nav.menu-type-main li`);
      const $menuMainElements = $menuMain.find('ul.navbar-nav.menu-type-main li > a');
      const $elements = $('div.wp-block-media-text, section.section, div.accordion');
      const $scrollContent = $('.scroll-content');



      const popupContainer = document.getElementById(`popupContainer`);

      
        
      scrollbar.addListener(scrollbarInfo => {

        // console.log(contextScrollableNodes.sliders)

        // console.log(scrollbarInfo)
        // let offsetY = getMenuHeight();

        // Sticky header START
        // header follows top window edge pretends "position: fixed" because smooth-scroll plugin uses translate3d
        // @see: https://github.com/idiotWu/smooth-scrollbar/issues/49
        // header.style.left = scrollbarInfo.offset.x + 'px';
        // if (scrollbarInfo.offset.y > scrollbarInfo.limit.y) {
        //   header.style.top = scrollbarInfo.limit.y + 'px';
        // } else if (scrollbarInfo.offset.y < 0) {
        //   header.style.top = 0;
        // } else {
        //   header.style.top = scrollbarInfo.offset.y + 'px';
        // }
        // @see: https://github.com/idiotWu/smooth-scrollbar/issues/91#issuecomment-322462625
        // header.style.transform = 'translate3d(' + scrollbarInfo.offset.x + 'px, ' + scrollbarInfo.offset.y + 'px, 0)';
        // @see: https://stackoverflow.com/questions/22111256/translate3d-vs-translate-performance
        header.style.transform = `translate3d(0, ${scrollbarInfo.offset.y}px, 0)`;



        if (popupContainer) {
          popupContainer.style.transform = `translate3d(0, ${scrollbarInfo.offset.y}px, 0)`;
        }



        // @see: https://stackoverflow.com/questions/36403101/toggle-class-in-react
        // header.classList.toggle(HEADER_STICKY_MENU_CLASS);
        const scrollCheck = scrollbarInfo.offset.y < offsetY;
        if (!scrollCheck) {
            // if (!header.classList.contains(HEADER_STICKY_MENU_CLASS)) {
              header.classList.add(HEADER_STICKY_MENU_CLASS);
            // }
        } else {
          // if (header.classList.contains(HEADER_STICKY_MENU_CLASS)) {
            header.classList.remove(HEADER_STICKY_MENU_CLASS);
          // }
        }


        // Highlight menu main elements when enter related section
        // @see: https://github.com/idiotWu/smooth-scrollbar/issues/121
        // if ($body.is(`.screen-lg, .screen-xl`)) {
          $menuMainElements
          // $($menuMainElements.get().reverse()) // @see: https://www.sitepoint.com/jquery-reverse-array-dom-elements/
            .each(function(index, value) {
            const $anchor = $(value);
            const $htmlAnchor = $anchor.attr(`href`).split(`#`)[1];
            
            // const $target = $(`#${$htmlAnchor}`);
            let $target = $(`#${$htmlAnchor}`);

            if (!$target.length) {
              return false;
            }    
            
            // @see: https://github.com/idiotWu/smooth-scrollbar/issues/240#issuecomment-594636701
            // let targetTop = $target.offset().top - $(container).offset().top // EXAMPLE
            let targetTop = $target.offset().top - $scrollContent.offset().top;
            let canFireEvent = scrollbarInfo.offset.y > targetTop - getMenuHeight() * 2;

            if (
                canFireEvent
                // && $target.length && scrollbar.isVisible($target[0]) 
            ) {
              // console.log($('.scroll-content').offset().top)
              // console.log($('.scroll-content')[0].offsetTop)
              // console.log(`EVENT FIRED FOR: `, $target.text())

              $menuMainLi.removeClass(`active`);
              value.parentNode.classList.add(`active`);
            }
          });
        // }



        // const $slickHiddenButton = $('.slick-hidden-button');

        // Mimic ScrollReveal
        $elements.each(function(index, value) {
          if (scrollbar.isVisible(value)) {
            // console.log('is visaible invoked');
            // $(value).addClass(`entered-view-port`);
            // if (!value.classList.contains(`animate__animated`)) {
              value.classList.add(`animate__animated`, `animate__fadeInUp`);
            // }
          } else {
            // $(value).removeClass(`entered-view-port`);
            // value.classList.remove(`entered-view-port`);

            // Reset slider when not in viewport
            const $slickHiddenButton = $(value).find('.slick-hidden-button');
            if (!$slickHiddenButton.hasClass(`slick-active`)) {
              $slickHiddenButton.find('a.btn').click();
            }

          }
        })



      });




      // // let offsetY = 100; // or calculate it dynamically!
      // let offsetY = headerRef.current ? headerRef.current.clientHeight : 1;

      // document.addEventListener(`scroll`, () => {

      //   // console.log(`scroll: `, scroll)
      //   // console.log(`offsetY: `, offsetY)
      //   // console.log(`window.scrollY: `, window.scrollY)
        
      //   const scrollCheck = window.scrollY < offsetY;
      //   // console.log(`scrollCheck: `, scrollCheck)
      //   if (scrollCheck !== scroll) {
      //     setScroll(scrollCheck)
      //   }
      // })

    }, []);

    // const isHeaderAlt = headerRef.current ? headerRef.current.classList.contains(HEADER_STICKY_MENU_CLASS) : false;

    const oHeaderClassNames = classNames({
        // [props.classNames]: true,
        'header navigation fixed-top': true,
        // [HEADER_STICKY_MENU_CLASS]: scroll, // for now we add class via header.classList.add(HEADER_STICKY_MENU_CLASS)
    });

    const oLinkLogoClassNames = classNames({
      'navbar-brand': true,
      'goto-scroll': Number(process.env.SOFT4NET_HOME_PAGE_DRUPAL_ID) === nodeId,
  });
*/
// SMOOTH_SCROLL STICKY HEADER PROCESSOR STOP






// Sticky header START
// @see: https://stackoverflow.com/questions/44612364/toggle-class-based-on-scroll-react-js#56606967
    const headerRef = useRef(null);
    // const getHeaderHeight = () => {
    //   return headerRef.current ? headerRef.current.clientHeight : 0;
    // }
    // const [headerHeight, setHeaderHeight] = useState(0);
    // useEffect(() => {
    //   const headerHeight = headerRef.current ? headerRef.current.clientHeight : 0;
    //   setHeaderHeight(headerHeight)
    // }, []);

    // const scroll = false;
    const scroll = useScrollHandler(headerRef);
    
    const HEADER_STICKY_MENU_CLASS_SCROLLED = `alt`;
    // const isHeaderAlt = headerRef.current ? headerRef.current.classList.contains(HEADER_STICKY_MENU_CLASS_SCROLLED) : false;
// console.log(isHeaderAlt)
// console.log(headerHeight)
    const oHeaderClassNames = classNames({
        // [props.classNames]: true,
        // 'header navigation fixed-top': true,
        'fixed-top': true,
        [HEADER_STICKY_MENU_CLASS_SCROLLED]: !scroll, // for now we add class via header.classList.add(HEADER_STICKY_MENU_CLASS_SCROLLED)
    });
// Sticky header STOP


    const countWishlistItems = () => {
      if (!isUserAuthenticated) {
        return false;
      }

      const { wishlist: { items, included } } = props;

      return items[0]?.relationships?.wishlist_items?.data?.length || 0;
    }


    return (
      <React.Fragment>
        <UseDrupalBlockContentBasic blockId={68} />

        <header
          id={`header-main`}

  // Sticky header START
          ref={headerRef} 
          className={oHeaderClassNames}
  // Sticky header STOP

  // SMOOTH_SCROLL STICKY HEADER PROCESSOR START
  // ref={headerRef}
  // className={oHeaderClassNames}
  // SMOOTH_SCROLL STICKY HEADER PROCESSOR STOP
        >

        <div id="navbar-wrapper">
          <div className={`container`}>
            <div className={`row`}>
              <div className={`col col-lg-5`}>

                  {/* <!-- navigation --> */}
                  <nav className="navbar navbar-expand-lg navbar-light" id="navbar">

                    <button tabIndex="0" className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label={t({ id: `soft4net_toggle_navigation` })}>
                      <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                      {/* <MenuMegaNav /> */}
                      <Menu type="main" injectContentToMenuId={injectContentToMenuId} />
                    </div>
                  </nav>
                  {/* <!-- /navigation --> */}

                  <div className="search-cart left">
                    <div className="search">
                      <button id="searchOpen" className="search-btn header-search-open">
                        <i className="ti-search"></i>
                        {/* <img src={IconSearch} alt={ t({ id: `soft4net_user_account` }) } /> */}
                      </button>
                      <Search />
                    </div>
                  </div>

              </div>
              <div className={`col col-lg-2`}>
                  {/* <a className="navbar-brand order-2 order-lg-1" href="index.html"><img className="img-fluid" src="/elite-shop/images/logo.png" alt="logo" /></a> */}
                  <Link 
                    {...linkLogoProps}
                    title={siteTitle} 
                    className="navbar-brand"
                  >
                    <img src={Logo} alt={siteTitle} className="img-fluid logo" />
                    {/* <span>Soft4Net</span> */}
                  </Link>
              </div>
              <div className={`col col-lg-5`}>

                  {/* <ul className="list-inline social-icons">
                    <li className="list-inline-item"><a href="https://www.facebook.com/decollywianki/" target="_blank"><img src={IconFacebook} alt={`Facebook`} /></a></li>
                    <li className="list-inline-item"><a href="https://www.instagram.com/decolly_wianki/" target="_blank"><img src={IconInstagram} alt={`Instagram`} /></a></li>
                    <li className="list-inline-item"><a href="https://pl.pinterest.com/decollywianki/" target="_blank"><img src={IconPinterest} alt={`Pinterest`} /></a></li>
                  </ul> */}

                  <div className="search-cart right">
                    <div className="search">
                      <button id="searchOpen" className="search-btn header-search-open">
                        <i className="ti-search"></i>
                        {/* <img src={IconSearch} alt={ t({ id: `soft4net_user_account` }) } /> */}
                      </button>
                      <Search />
                    </div>
                  </div>

                  {/* <Button variant="outlined" component={Link} to="/user/profile">My Account</Button> */}
                  {/* <Login /> */}
                  <div id="menu-account-management" className="account-item dropdown show">
                    
                    {isUserAuthenticated ?
                      <React.Fragment>
                        <a className="_btn _btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLinkAccountManagement" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <img src={IconUserAccount} alt={ t({ id: `soft4net_user_account` }) } />
                          <span className={`d-none d-xl-inline-block`}>{props?.user?.user_profile?.attributes?.display_name}</span>
                        </a>
                      </React.Fragment>
                      :
                      <React.Fragment>
                        {/* <Link to={`/user/`} className="btn _btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLinkAccountManagement" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{ t({ id: `soft4net_user_account` }) }</Link> */}
                        <a className="_btn _btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLinkAccountManagement" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <img src={IconUserAccount} alt={ t({ id: `soft4net_user_account` }) } />
                          <span className={`d-none d-xl-inline-block`}>{ t({ id: `soft4net_user_account` }) }</span>
                        </a>
                      </React.Fragment>
                    }

                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLinkAccountManagement">
                      {!isUserAuthenticated && 
                        <React.Fragment>
                          {/* <Link to={`/user/login`} className="dropdown-item btn text-center" href="#">{ t({ id: `soft4net_menu_account_management_login` }) }</Link>
                          <p className="px-4 mb-0 text-center">{ t({ id: `soft4net_menu_account_management_encouragement_create_account` }) }</p>
                          <Link to={`/user/register`} className="dropdown-item btn text-center" href="#">{ t({ id: `soft4net_menu_account_management_register` }) }</Link> */}
                          <Link to={`/user/login-register`} className="dropdown-item" href="#">{ t({ id: `soft4net_menu_account_management_login` }) }</Link>
                          {/* <p className="px-4 mb-0 text-center">{ t({ id: `soft4net_menu_account_management_encouragement_create_account` }) }</p> */}
                          <Link to={`/user/login-register`} className="dropdown-item" href="#">{ t({ id: `soft4net_menu_account_management_register` }) }</Link>
                        </React.Fragment>
                      }

                      {/* <Link to={`/user/`} className="btn _btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLinkAccountManagement" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{ t({ id: `soft4net_user_account` }) }</Link> */}
                      <Link to={`/user/profile`} className="dropdown-item" href="#">{ t({ id: `soft4net_menu_account_management_user_profile` }) }</Link>
                      {/* <Link to={`/user/commerce/orders`} className="dropdown-item" href="#">{ t({ id: `soft4net_menu_account_management_commerce_orders` }) }</Link> */}
                      {/* <Link to={`/user/commerce/wishlist`} className="dropdown-item" title={ t({ id: `soft4net_menu_account_management_commerce_wishlist` }) }>{ t({ id: `soft4net_menu_account_management_commerce_wishlist` }) }</Link> */}
                      {/* <Link to={`/user/commerce/address`} className="dropdown-item" href="#">{ t({ id: `soft4net_account_management_page_DASHBOARD_ADDRESS` }) }</Link> */}
                      {/* <Link to={`/user/newsletter`} className="dropdown-item" href="#">{ t({ id: `soft4net_menu_account_management_user_newsletter` }) }</Link> */}

                      {isUserAuthenticated && 
                        <React.Fragment>
                          <div className="dropdown-divider"></div>
                          <LogoutLink component={Link} className="dropdown-item mt-2">Wyloguj</LogoutLink>
                        </React.Fragment>
                      }
                    </div>
                  </div>

                  <Link to={`/user/commerce/wishlist`} className="wishlist-item" title={ t({ id: `soft4net_user_wishlist` }) }>
                    <img src={IconUserWishlist} alt={ t({ id: `soft4net_user_wishlist` }) } />
                    <span className={`d-none d-xl-inline-block`}>
                      { t({ id: `soft4net_user_wishlist` }) }
                      {countWishlistItems() !== false ? <React.Fragment> ({countWishlistItems()})</React.Fragment> : ` `}
                    </span>
                    {countWishlistItems() !== false ? 
                      <span className={`counter mobile`}>
                        {countWishlistItems()}
                      </span>
                      : ` `
                    }
                  </Link>

                  <div className="cart-item search-cart">
                    {/* <!-- cart --> */}
                    {/* {`null` !== process.env.SOFT4NET_SHOP_PAGE_DRUPAL_ID &&  */}
                      <CartFlyout />
                    {/* } */}
                  </div>

                  <div className={`d-none`}>
                    <LanguageSwitcher currentMenuItemTranslations={MenuUtils.getCurrentMenuItemTranslations()} />
                  </div>


              </div>
            </div>

          </div>
        </div>

        </header>
      </React.Fragment>
    )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

const mapStateToProps = state => ({
  user: state.shopReducers.user,
  wishlist: state.shopReducers.wishlist,
})

export default connect(mapStateToProps)(withDrupalOauthConsumer(Header));
// export default withStyles(styles)(withDrupalOauthConsumer(Navigation));