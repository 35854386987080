// @see: https://www.gatsbyjs.org/docs/using-fragments/
import { graphql } from "gatsby"
import FragmentProductRelated from '../commerceProductDefault/product-related'

export const query = graphql`
    fragment FragmentProductIndex on CommerceProduct {

        id
        drupal_id
        # drupal_internal__nid
        drupal_internal__product_id
        internal {
            type
        }

        langcode
        title
        path {
            pid
            alias
            langcode
        }
        body {
            summary
        }
        created

        relationships {
            bundle: __typename

            variations {
                ... on commerce_product_variation__default {

                    id: drupal_id # JSON:API response id
                    type: __typename # JSON:API response type, not exactly the same because it is "commerce_product_variation__phone", while JSON:API is "commerce_product_variation--phone" we need to convert it

                    variation_id: drupal_internal__variation_id
                    sku
                    title
                    price {
                        number
                        currency_code
                        formatted
                    }
                    resolved_price {
                        number
                        currency_code
                        formatted
                    }
                    calculated_price {
                        number
                        currency_code
                        formatted
                    }
                    relationships {
                        bundle: __typename

                        field_images {
                            localFile {
                                childImageSharp {
                                    fluid(
                                        # maxWidth: 340,
                                        srcSetBreakpoints: [ 1200 ]
                                    ) {
                                        # ...GatsbyImageSharpFluid_withWebp
                                        ...GatsbyImageSharpFluid_withWebp_noBase64
                                    }
                                    # fluid(maxWidth: 100) {
                                    #     ...GatsbyImageSharpFluid_withWebp
                                    # }
                                    # fixed(width: 400, height: 400) {
                                    #     ...GatsbyImageSharpFixed
                                    # }
                                }
                            }
                        }
                    }
                    commerce_stock_always_in_stock
                    field_stock_level {
                        available_stock
                    }
                }

                # ... on commerce_product_variation__phone {

                #     id: drupal_id # JSON:API response id
                #     type: __typename # JSON:API response type, not exactly the same because it is "commerce_product_variation__phone", while JSON:API is "commerce_product_variation--phone" we need to convert it

                #     variation_id: drupal_internal__variation_id
                #     sku
                #     title
                #     price {
                #         number
                #         currency_code
                #         formatted
                #     }
                #     resolved_price {
                #         number
                #         currency_code
                #         formatted
                #     }
                #     calculated_price {
                #         number
                #         currency_code
                #         formatted
                #     }
                #     relationships {
                #         bundle: __typename

                #         field_images {
                #             localFile {
                #                 childImageSharp {
                #                     fluid(maxWidth: 340) {
                #                         ...GatsbyImageSharpFluid_withWebp
                #                     }
                #                 }
                #             }
                #         }
                #     }
                #     commerce_stock_always_in_stock
                #     field_stock_level {
                #         available_stock
                #     }
                # }
            }

            # field_menu_item {
            #     title
            # }

            field_category {
                ... on taxonomy_term__kategorie_sklep {
                    name
                }
            }

            # field_related_products {
            #     ... on CommerceProduct {
            #         ...FragmentProductRelated
            #     }
            # }

            # commerce_product_variation__default {
            # 	sku
            #     price {
            #         number
            #         currency_code
            #     }
            #     ... on commerce_product_variation__default {
            #     }
            # }

        }

        # commerceStoreOnline___relationships___field_promoted_category_weight,

    }
`