import React from 'react';
import { connect } from 'react-redux'
import withDrupalOauthConsumer from './../../../drupal-oauth/withDrupalOauthConsumer';

// s4n
import { injectIntl } from "gatsby-plugin-intl"
import { userLogout } from './../../Shop/ext/redux/actions/user'



const LogoutLink = (props) => {
  if (!props.drupalOauthClient) {
    return null
  }

  const { component: Component } = props;

  return(
      <Component
          {...props}
          onClick={async (e) => {
              e.preventDefault();
              await props.drupalOauthClient.handleLogout();
              props.updateAuthenticatedUserState(false);
              props.onUserLogout();
          }}
    >
      {props.children ? props.children : `Log out`}
    </Component>
  ); 
};

const mapStateToProps = null;

const mapDispatchToProps = dispatch => ({
    onUserLogout: () => dispatch(userLogout()),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withDrupalOauthConsumer(LogoutLink)));