/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useReducer, useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"

// import Header from "./header"
import Header from "./header"
import Footer from "./footer"

// import "./layout.css"
// import cssModule from "./../assets/sass/components/Layout.module.scss"
// import "./../../node_modules/bootstrap/scss/bootstrap.scss";
import "./../assets/sass/index.scss"
// import cssModuleHighContrast from "./../assets/sass/HighContrast.module.scss"
// import cssModuleHighContrast from "./../assets/sass/HighContrast.module.css"
// import "./../assets/sass/HighContrast.module.css"

// s4n
// import $ from "jquery"
import { useIntl, navigate, FormattedHTMLMessage } from "gatsby-plugin-intl"
import loadable from '@loadable/component'
// import Preloader from './s4n/Preloader'
import kebabCase from "lodash.kebabcase"
import classNames from 'classnames';
import S4nLayoutBlock from './s4n/layoutBlock'
// import * as loadScript from 'simple-load-script'
// import loadScript from 'simple-load-script';
// @see: https://github.com/gatsbyjs/gatsby/issues/8755#issuecomment-456766798
import { withPrefix } from 'gatsby'
// import g_soft4NetFrontConfig from './../assets/js/s4n'
// import YupSetLocale from './../components/forms/yup-setlocale';

import drupalOauth from './drupal-oauth/drupalOauth';
import withDrupalOauthProvider from './drupal-oauth/withDrupalOauthProvider'

import { connect } from 'react-redux'
import { intlPreserve } from './../s4n/state/actions/intl'

// SHOP EXT START
// // import React, { Component } from 'react';
// // import { ApolloProvider } from 'react-apollo';
// // import { Route, Switch } from "react-router-dom";
// import { Provider } from 'react-redux';
// import { ConnectedRouter } from 'connected-react-router'
// import configureStore, { history } from './../components/s4n/Shop/ext/utils/configureStore';
// // import { graphqlClient } from './utils/api';

// // import Header from './blocks/Header';
// // import CatalogMenu from './blocks/CatalogMenu'
// // import Footer from './blocks/Footer'
// // import Home from './pages/Home';
// import Cart from './../components/s4n/Shop/ext/pages/Cart';
// // import Catalog from './pages/Catalog';
// // import Product from './pages/Product';
// import { cartFetch, setCartToken } from './../components/s4n/Shop/ext/actions'
// import CartFlyout from './../components/s4n/Shop/ext/blocks/CartFlyout'
// // import Checkout from './pages/Checkout'

//     // @see: https://github.com/gatsbyjs/gatsby/issues/14480
//     /**
//      * I do localStorage (typeof window !== 'undefined') way instead of putting invocation into useEffect()
//      * because render is called before useEffect()!
//      */
//     // const store = configureStore();
//     // // if (typeof window !== 'undefined') {
//     //   store.dispatch(setCartToken(localStorage.getItem('cartToken') || Math.random().toString(36).substr(2)))
//     //   // store.dispatch(setCartToken('ajg6t2g3kz'));
//     //   store.dispatch(cartFetch());
//     // // }
// SHOP EXT STOP

// s4n
import useVersion from './../hooks/useVersion'
import useScreenSize from './../hooks/useScreenSize'

import axios from 'axios';
import cn from 'classnames'
import ImageS4n from "./s4n/image"
import Popup from './../components/s4n/Popup'
// @see: https://fullstackheroes.com/react/exit-intent-react/
import ExitIntent from "../utils/ExitIntent"

// import ShopCheckoutIframe from './../components/s4n/Shop/s4n/Checkout/Iframe'



// SMOOTH_SCROLL START
// @see: https://github.com/idiotWu/react-smooth-scrollbar
// import SmoothScrollbar from 'smooth-scrollbar';
// import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll';
// import Scrollbar from 'react-smooth-scrollbar';
// SMOOTH_SCROLL STOP


// process.env.NODE_ENV is set only in Express server not in running contexte from simple node invoke!!!
// @see: https://stackoverflow.com/questions/16978256/what-is-node-env-in-express
if (`development` === process.env.NODE_ENV) {
  // @see: https://github.com/formatjs/formatjs/issues/465#issuecomment-369566628 START
  // Disable missing translation message as translations will be added later.
  // We can add a toggle for this later when we have most translations.

  // eslint-disable-next-line
  const consoleError = console.error.bind(console);
  // eslint-disable-next-line
  console.error = (message, ...args) => {
    if (
      typeof message === 'string' && (
        message.startsWith('[React Intl] Missing message:') || 
        message.startsWith('[React Intl] Cannot format message:')
      )
    ) {
      return;
    }
    consoleError(message, ...args);
  };
  // @see: https://github.com/formatjs/formatjs/issues/465#issuecomment-369566628 STOP
}



const Layout = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { children } = props;

    const [context, setContext] = useState({
      // loadedScripts: false,
      hasTopAnnouncementBar: true,
      windowLoaded: false,
    });

    const [showPopupExitIntent, setShowPopupExitIntent] = useState(false)

    const currentBuildVersion = useVersion();

    // const [htmlElement, setHtmlElement] = useState(null);

// SMOOTH_SCROLL START
    // const scrollbarRef = useRef(null);
// SMOOTH_SCROLL STOP

    // SHOP EXT START
    // @see: https://github.com/gatsbyjs/gatsby/issues/14480
    /**
     * I do localStorage (typeof window !== 'undefined') way instead of putting invocation into useEffect()
     * because render is called before useEffect()!
     */
    // const store = configureStore();
    // // if (typeof window !== 'undefined') {
    //   // store.dispatch(setCartToken(localStorage.getItem('cartToken') || Math.random().toString(36).substr(2)))
    //   store.dispatch(setCartToken('9jg6t2g3kz'));
    //   store.dispatch(cartFetch());
    // // }
    // SHOP EXT STOP
   
    /**
     * Load 3rd party scripts async in order (local static assets mainly thanks to withPrefix)!!!
     * 
     * @see: https://stackoverflow.com/questions/34589488/es6-immediately-invoked-arrow-function
     * @see: https://stackoverflow.com/questions/40958680/react-router-how-to-trigger-document-ready
     */
    /*
      #!/bin/bash

      # create symlinks from sticky directory for local npm packages that you want to inject into body
      cd static/
      ln -s ../node_modules/jquery/dist/jquery.min.js jquery.min.js
      ln -s ../node_modules/jquery/dist/jquery.slim.min.js jquery.slim.min.js
      // ln -s ../node_modules/bootstrap/dist/js/bootstrap.min.js bootstrap.min.js
      ln -s ../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js bootstrap.bundle.min.js
      ln -s ../node_modules/owl.carousel/dist/owl.carousel.min.js owl.carousel.min.js
      ln -s ../node_modules/waypoints/lib/jquery.waypoints.min.js jquery.waypoints.min.js
      ln -s ../node_modules/waypoints/lib/shortcuts/sticky.min.js sticky.min.js
      ln -s ../node_modules/magnific-popup/dist/jquery.magnific-popup.min.js jquery.magnific-popup.min.js
      ln -s ../node_modules/smooth-scroll/dist/smooth-scroll.polyfills.min.js smooth-scroll.polyfills.min.js
    */
    const loadScripts = async (nodeId, productId) => {

      




      // const simpleLoadScript = (function (root, factory) {
      //   if (typeof define === 'function' && define.amd) {
      //     define([], factory);
      //   } else if (typeof module === 'object' && module.exports) {
      //     module.exports = factory();
      //   } else {
      //     root.simpleLoadScript = factory();
      //   }
      // }(this, function () {
      //   var undef;
      
      //   function deleteFromGlobal(name) {
      //     try {
      //       delete window[name];
      //     } catch (e) {
      //       window[name] = null;
      //     }
      //   }
      
      //   function getScript(url, options) {
      //     return new Promise(function (resolve, reject) {
      //       if (typeof url === 'object') {
      //         options = url;
      //         url = options.url;
      //       }
      //       if (!options) options = {};
      //       if (!url) {
      //         reject('Error: no script url');
      //         return;
      //       }
      //       var script = document.createElement('script');
      //       var where = (function () {
      //         if (options.insertInto) {
      //           return document.querySelector(options.insertInto);
      //         }
      //         return options.inBody ? document.body : document.head;
      //       }());
      //       if (!where) {
      //         reject('Error: no DOM element to append script');
      //         return;
      //       }
      //       var attrs = options.attrs;
      //       var removeScript = options.removeScript;
      //       var callBackName = options.callBackName;
      //       for (var attr in attrs) {
      //         if (Object.prototype.hasOwnProperty.call(attrs, attr)) {
      //           script.setAttribute(attr, attrs[attr]);
      //         }
      //       }
      //       if (!callBackName) {
      //         script.addEventListener('load', function () {
      //           if (removeScript) where.removeChild(script);
      //           resolve(removeScript ? undef : script);
      //         });
      //       } else {
      //         window[callBackName] = function (res) {
      //           if (!res) res = removeScript ? undef : script;
      //           if (!options.dontRemoveCallBack) deleteFromGlobal(callBackName);
      //           if (removeScript) where.removeChild(script);
      //           resolve(res || removeScript ? undef : script);
      //         };
      //       }
      //       script.addEventListener('error', function () {
      //         where.removeChild(script);
      //         reject('Error: loading script');
      //       });
      //       script.src = url;
      //       where.appendChild(script);
      //     });
      //   }
      
      //   // array of urls or array of objects
      //   function all() {
      //     if (!arguments.length) return Promise.reject(new Error('No files or no file configs'));
      //     return Promise.all(Array.prototype.slice.call(arguments).map(function (e) {
      //       return Array.isArray(e) ? getScript.apply(null, e) : getScript(e);
      //     }));
      //   }
      
      //   getScript.deleteFromGlobal = deleteFromGlobal;
      //   getScript.all = all;
      
      //   return getScript;
      // }));
      

        // const loadScript = simpleLoadScript();
        const loadScript = function getScript(url, options) {
          return new Promise(function (resolve, reject) {
            if (typeof url === 'object') {
              options = url;
              url = options.url;
            }
            if (!options) options = {};
            if (!url) {
              reject('Error: no script url');
              return;
            }
            var script = document.createElement('script');
            var where = (function () {
              if (options.insertInto) {
                return document.querySelector(options.insertInto);
              }
              return options.inBody ? document.body : document.head;
            }());
            if (!where) {
              reject('Error: no DOM element to append script');
              return;
            }
            var attrs = options.attrs;
            var removeScript = options.removeScript;
            var callBackName = options.callBackName;
            for (var attr in attrs) {
              if (Object.prototype.hasOwnProperty.call(attrs, attr)) {
                script.setAttribute(attr, attrs[attr]);
              }
            }
            if (!callBackName) {
              script.addEventListener('load', function () {
                if (removeScript) where.removeChild(script);
                resolve(removeScript ? undef : script);
              });
            } else {
              window[callBackName] = function (res) {
                if (!res) res = removeScript ? undef : script;
                if (!options.dontRemoveCallBack) deleteFromGlobal(callBackName);
                if (removeScript) where.removeChild(script);
                resolve(res || removeScript ? undef : script);
              };
            }
            script.addEventListener('error', function () {
              where.removeChild(script);
              reject('Error: loading script');
            });
            script.src = url;
            where.appendChild(script);
          });
        }



        // CDN assets
        // await loadScript('https://code.jquery.com/jquery-3.4.1.slim.min.js', { inBody: true });
        // await loadScript('https://code.jquery.com/jquery-3.4.1.min.js', { inBody: true });
        // await loadScript(withPrefix('/jquery.slim.min.js' + currentBuildVersion), { inBody: true }); // does not contain .animate() !!!
        await loadScript(withPrefix(`/node_modules/jquery.min.js` + currentBuildVersion), { inBody: true });
        // await loadScript(withPrefix('/bootstrap.min.js' + currentBuildVersion), { inBody: true });
        // await loadScript(withPrefix('/bootstrap.bundle.min.js' + currentBuildVersion), { inBody: true });
        await loadScript(withPrefix(`/node_modules/bootstrap.bundle.min.js` + currentBuildVersion), { inBody: true }); // bootstrap.bundle.min.js include Popper, but not jQuery
        // await loadScript(withPrefix(`/node_modules/jquery.waypoints.min.js` + currentBuildVersion), { inBody: true });
        // await loadScript(withPrefix('/sticky.min.js' + currentBuildVersion), { inBody: true });
// await loadScript(withPrefix('/node_modules/smooth-scroll.polyfills.min.js' + currentBuildVersion), { inBody: true });
        // await loadScript(withPrefix('/jquery.magnific-popup.min.js' + currentBuildVersion), { inBody: true });
        
        // Load only at page [/pl/o-nas/historia-i-fakty || /en/about-us/history-and-facts]
        // 1055 === nodeId && 
          // await loadScript(withPrefix('/scrollreveal.min.js' + currentBuildVersion), { inBody: true }); // await loadScript('https://unpkg.com/scrollreveal', { inBody: true });

        // await loadScript(withPrefix(`/elite-shop/plugins/slick/slick.min.js` + currentBuildVersion), { inBody: true });
        await loadScript(withPrefix(`/elite-shop/plugins/venobox/venobox.min.js` + currentBuildVersion), { inBody: true });
        await loadScript(withPrefix(`/elite-shop/plugins/aos/aos.js` + currentBuildVersion), { inBody: true });
        await loadScript(withPrefix(`/elite-shop/plugins/syotimer/jquery.syotimer.js` + currentBuildVersion), { inBody: true });
        await loadScript(withPrefix(`/elite-shop/plugins/instafeed/instafeed.min.js` + currentBuildVersion), { inBody: true });
        await loadScript(withPrefix(`/elite-shop/plugins/zoom-master/jquery.zoom.min.js` + currentBuildVersion), { inBody: true });
        await loadScript(withPrefix(`/elite-shop/plugins/bootstrap-touchspin-master/jquery.bootstrap-touchspin.min.js` + currentBuildVersion), { inBody: true });
        await loadScript(withPrefix(`/elite-shop/plugins/nice-select/jquery.nice-select.min.js` + currentBuildVersion), { inBody: true });
        await loadScript(withPrefix(`/elite-shop/plugins/bootstrap-slider/bootstrap-slider.min.js` + currentBuildVersion), { inBody: true });
        
        /**
         * Disable parent theme scripts.js and call out customized version in s4n-theme-custom.js
         */
        // await loadScript(withPrefix(`/elite-shop/js/script.js` + currentBuildVersion), { inBody: true });
        // await loadScript(withPrefix(`/s4n-theme-custom.js` + currentBuildVersion), { inBody: true });

        // @todo: use https://jscompress.com/
        await loadScript(withPrefix(`/s4n.min.js` + currentBuildVersion), {
        // await loadScript(withPrefix('/s4n.js' + currentBuildVersion), {
          inBody: true,
          /**
           * @see: https://stackoverflow.com/questions/3206050/extract-src-attribute-from-script-tag-and-parse-according-to-particular-matches
           * @see: https://stackoverflow.com/questions/2190801/passing-parameters-to-javascript-files
           */
          // attrs: {
          //   'data-soft4net-source-base-url': process.env.SOFT4NET_SOURCE_BASE_URL,
          // }
        });

        // const jQuery = window.jQuery;
        // const $ = jQuery;

        // We have now available window.g_soft4NetFrontConfig from s4n.js !!!
        callScripts(nodeId, productId);
    }

    const callScripts = (nodeId, productId) => {
        const g_soft4NetFrontConfig = window.g_soft4NetFrontConfig;
        const $ = window.jQuery; // we want to have jQuery from our import not from => import $ from "jquery"






// @see: /s4n-theme-custom.js START

    // Subscription Modal Show
    var show = function () {
      $('#subscriptionModal').modal('show');
    };
    // Preloader
    $(window).on('load', function () {
      $('.preloader').fadeOut('slow', function () {
        $(this).remove();
      });
      var timer = window.setTimeout(show, 2000);
    });
    
    // Background-images
    $('[data-background]').each(function () {
      $(this).css({
        'background-image': 'url(' + $(this).data('background') + ')'
      });
    });
  
    //  Search Form Open
    // $('#searchOpen').on('click', function () {
    $('.header-search-open').on('click', function () {
      $('.search-wrapper').toggleClass('open');
      $('.search-btn').toggleClass('search-close');
      
      $('.search-box input').focus();
    });
  
    //  Cart Open
// @CHANGE START
    // $('#cartOpen').on('click', function () {
    //   $('.cart-wrapper').addClass('open');
    // });
    // $('#cartClose').on('click', function () {
    //   $('.cart-wrapper').removeClass('open');
    // });
// @CHANGE STOP
  
    //Hero Slider
    // $('.hero-slider').slick({
    //   autoplay: true,
    //   autoplaySpeed: 7500,
    //   lazyLoad: 'progressive',
    //   speed: 100,
    //   pauseOnFocus: false,
    //   pauseOnHover: false,
    //   infinite: true,
    //   arrows: true,
    //   prevArrow: '<button type=\'button\' class=\'prevArrow\'></button>',
    //   nextArrow: '<button type=\'button\' class=\'nextArrow\'></button>',
    //   dots: false,
    //   responsive: [{
    //     breakpoint: 576,
    //     settings: {
    //       arrows: false
    //     }
    //   }]
    // });
    // $('.hero-slider').slickAnimation();
  
    // collection slider
    // $('.collection-slider').slick({
    //   dots: true,
    //   speed: 300,
    //   autoplay: false,
    //   autoplaySpeed: 5000,
    //   arrows: false,
    //   slidesToShow: 4,
    //   slidesToScroll: 4,
    //   responsive: [{
    //       breakpoint: 1024,
    //       settings: {
    //         slidesToShow: 3,
    //         slidesToScroll: 3
    //       }
    //     },
    //     {
    //       breakpoint: 768,
    //       settings: {
    //         slidesToShow: 2,
    //         slidesToScroll: 2
    //       }
    //     },
    //     {
    //       breakpoint: 480,
    //       settings: {
    //         slidesToShow: 1,
    //         slidesToScroll: 1
    //       }
    //     }
    //   ]
    // });
  
    // product Slider
    // $('.product-slider').slick({
    //   autoplay: false,
    //   infinite: true,
    //   arrows: true,
    //   prevArrow: '<button type=\'button\' class=\'prevArrow\'><i class=\'ti-arrow-left\'></i></button>',
    //   nextArrow: '<button type=\'button\' class=\'nextArrow\'><i class=\'ti-arrow-right\'></i></button>',
    //   dots: true,
    //   customPaging: function (slider, i) {
    //     var image = $(slider.$slides[i]).data('image');
    //     return '<img class="img-fluid" src="' + image + '" alt="product-img">';
    //   }
    // });

    //  collection item quick view
    // $('.venobox').venobox({
    //   framewidth: '80%',
    //   frameheight: '100%'
    // });
  
    // deal timer
    // var dealYear = $('#simple-timer').attr('data-year');
    // var dealMonth = $('#simple-timer').attr('data-month');
    // var dealDay = $('#simple-timer').attr('data-day');
    // var dealHour = $('#simple-timer').attr('data-hour');
    // $('#simple-timer').syotimer({
    //   year: dealYear,
    //   month: dealMonth,
    //   day: dealDay,
    //   hour: dealHour,
    //   minute: 0
    // });
  
  
    // sale timer
    // var saleYear = $('#sale-timer').attr('data-year');
    // var saleMonth = $('#sale-timer').attr('data-month');
    // var saleDay = $('#sale-timer').attr('data-day');
    // var saleHour = $('#sale-timer').attr('data-hour');
    // $('#sale-timer').syotimer({
    //   year: saleYear,
    //   month: saleMonth,
    //   day: saleDay,
    //   hour: saleHour,
    //   minute: 0
    // });
  
    // Count Down JS
    // $('#comingSoon').syotimer({
    //   year: 2019,
    //   month: 5,
    //   day: 9,
    //   hour: 20,
    //   minute: 30
    // });
  
    // instafeed
    // if (($('#instafeed').length) !== 0) {
    //   var userId = $('#instafeed').attr('data-userId');
    //   var accessToken = $('#instafeed').attr('data-accessToken');
    //   var userFeed = new Instafeed({
    //     get: 'user',
    //     userId: userId,
    //     resolution: 'low_resolution',
    //     accessToken: accessToken,
    //     limit: 6,
    //     template: '<div class="col-lg-2 col-md-3 col-sm-4 col-6 px-0 mb-4"><div class="instagram-post mx-2"><img class="img-fluid w-100" src="{{image}}" alt="instagram-image"><ul class="list-inline text-center"><li class="list-inline-item"><a href="{{link}}" target="_blank" class="text-white"><i class="ti-heart mr-2"></i>{{likes}}</a></li><li class="list-inline-item"><a href="{{link}}" target="_blank" class="text-white"><i class="ti-comments mr-2"></i>{{comments}}</a></li></ul></div></div>'
    //   });
    //   userFeed.run();
    // }
  
    // image zoom
    // $('.image-zoom')
    //   .wrap('<span></span>')
    //   .css('display', 'block')
    //   .parent()
    //   .zoom({
    //     on: 'click',
    //     url: $(this).find('img').attr('data-zoom')
    //   });
  
    // touchspin
    // $('input[name=\'quantity\']').TouchSpin({
    //   verticalbuttons: true,
    //   initval: 1,
    //   verticalupclass: 'angle-up',
    //   verticaldownclass: 'angle-down'
    // });
    // $('input[name=\'cart-quantity\']').TouchSpin({
    //   initval: 40
    // });
  
    // nice select
// @CHANGE START
    // $('select').niceSelect();
// @CHANGE STOP
  
    // checked
    $('.label').click(function () {
      $(this).find('.size-checkbox').toggleClass('checked');
    });
  
    // bootstrap slider range
    $('.range-track').slider({});
    $('.range-track').on('slide', function (slideEvt) {
      $('.value').text('$' + slideEvt.value[0] + ' - ' + '$' + slideEvt.value[1]);
    });
  
    // tooltip
    // $(function () {
    //   $('[data-toggle="tooltip"]').tooltip();
    // });
  
    // sticky-menu
// @CHANGE START
    // var navbar = $('#navbar');
/*
    var navbar = $('#navbar-wrapper');
    var mainWrapper = $('.main-wrapper');
    var sticky = navbar.offset().top;
    $(window).scroll(function () {
      if ($(document).scrollTop() >= sticky) {
        navbar.addClass('sticky');
        mainWrapper.addClass('main-wrapper-section');
      } else {
        navbar.removeClass('sticky');
        mainWrapper.removeClass('main-wrapper-section');
      }
    });
*/
// @CHANGE STOP

// @see: /s4n-theme-custom.js STOP






        $(document).ready(() => {
        // $(document).on('ready', function() { // NOT WORKING WHY???!!!
            g_soft4NetFrontConfig
                .rot13()
                // .stickyHeaderAction()
                // .smoothClickScrolling()
                // .enableMagnificPopup(process.env.SOFT4NET_SOURCE_BASE_URL)
                .enableScrollTopService()
                .transformToBootstrapAccoridion()
                // .enableTimeline()
                // .enableServiceTawkTo()
                // .updateBootstrapMenu()
                // .scrollSpy()
                // .waypoints()
                // .owlCarousel()
                // .servicePopupScroll()
                // .fancybox()
            ;
        });

        // Run a function when the page is fully loaded including graphics
        // $(window).on('load', function() {
        // });

        g_soft4NetFrontConfig
            // .webFont()
            // .navTransformicon()
            // .smoothClickScrolling()
            // .bootstrapMenu()
            // .stickyHeaderAction()
            .otherActions()
            // .setScreenSize()
            // .owlCarousel('mainSlider', '.owl-carousel.hero')
            // .owlCarousel('centeredSlider', '.owl-carousel.centered')
            // .owlCarousel('standardSlider', '.owl-carousel.standard')
            // .owlCarousel('testimonialsSlider', '.owl-carousel.testimonials')
        ;



      // $('li.dropdown').on('click', function() {
      //   let $el = $(this);
      //   if ($el.hasClass('show')) {
      //       let $a = $el.children('a.dropdown-toggle');

      //       if ($a.length && $a.attr('href')) {
      //           // location.href = $a.attr('href');
      //           navigate($a.attr('href'));

      //           console.log('navigate')
      //       }
      //   }
      // });



        // Make bootstrap a.dropdown-toggle that contains submenus clickable
        const $body = $(`body`);
        // $('.navbar').on('click', 'a.dropdown-toggle', function(event) {
        $(`.navbar .navbar-nav.menu-type-main`).on(`click`, `a.dropdown-toggle`, function (event) { // (event) => { // This cannot be here since it has different this!!!
          // do not visit link for mobile
          event.preventDefault();
 
          // visit link in non mobile
          // if ($body.hasClass('screen-lg') || $body.hasClass('screen-xl')) {
          if ($body.is(`.screen-lg, .screen-xl`)) {
            const $a = $(this);

            // Do not touch language switcher!!!
            if (`langDropdown` === $a.attr(`id`)) {
              return false;
            }

            // if ($a.parent().hasClass('show')) {
                if ($a.length && $a.attr(`href`)) {
                    // location.href = $a.attr('href');
                    
                    let href = $a.attr(`href`);
                        href = href.replace(`/` + intl.locale, ``);

                    navigate(href);
                    return null;
                }
            // }
          }
        });
  
  
  
        // @see: https://bootstrapious.com/p/bootstrap-multilevel-dropdown
        // ------------------------------------------------------- //
        // Multi Level dropdowns
        // ------------------------------------------------------ //
        $(`ul.dropdown-menu [data-toggle='dropdown']`).on(`click`, function(event) {
// s4n START
            // allow language switcher to be clicked!!!
            if ('langDropdown' === $(this).attr('id')) {
              return true;
            }
// s4n STOP
            event.preventDefault();
            event.stopPropagation();
    
            $(this).siblings().toggleClass("show");
    
            if (!$(this).next().hasClass('show')) {
              $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
            }
            $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(event) {
              $('.dropdown-submenu .show').removeClass("show");
            });
        });


        $('.navbar-nav.menu-type-main li:not(.dropdown).active > a').on('click', function() {
          $('#navbarSupportedContent.navbar-collapse').collapse(`hide`);
        });



        if (nodeId === Number(process.env.SOFT4NET_HOME_PAGE_DRUPAL_ID)) {
          $(`#footer-logo`).on(`click`, function(event) {
            event.preventDefault();
            window.scrollTo(0, 0);
          });
        }


        const $hasTopAnnouncementBar = $('.block-internal-id-68')
        if (!$hasTopAnnouncementBar.length) {
          setContext({
            hasTopAnnouncementBar: false,
          })
        }


        $('body').tooltip({
          selector: '[data-toggle="tooltip"]',
          // placement: 'bottom',
          container: 'body',
        });
        
        $('body').popover({
          selector: '[data-toggle="popover"]',
          trigger: 'hover focus',
          // placement: 'bottom',
          container: 'body',
        });

        $(`div.modal-backdrop, div.popover`).remove(); // fix non disapearing bootstrap modal window when select in mobile category or filter!!!

        
    }



    const reduxStateInitial = {
      commerceStoreOnlineData: null,
    };
    const reducer = (state, action) => {
      switch (action.type) {
        case `setCommerceStoreOnlineData`:
          return {
            ...state,
            commerceStoreOnlineData: action.payload,
          };
        default:
          throw new Error('Unexpected action');
      }
    };
    const [reduxState, dispatch] = useReducer(reducer, reduxStateInitial);

/*
{
  field_popup_newsletter_enabled: "1",
  field_popup_newsletter_delay: "0",
  field_popup_exitintent_enabled: "1"
}
*/
    const loadCommerceStoreOnlineData = async () => {
      if (null !== reduxState.commerceStoreOnlineData) {
        return false;
      }

      // https://api.decolly.pl/pl/jsonapi/commerce_store/online
      // https://api.decolly.pl/pl/soft4net/main/commerce_store_online_data
      const formSchemaEndpoint = `/soft4net/main/commerce_store_online_data`;

      let response = null, 
          data = null;

      try {
          response = await axios.get(`${process.env.SOFT4NET_SOURCE_BASE_URL}/${intl.locale}${formSchemaEndpoint}`);
          data = response.data;
// console.log(`data: `, data)         
          dispatch({ type: `setCommerceStoreOnlineData`, payload: data })
      } catch (e) {
          console.log(e);
      }
      
      return true;
    }






    /**
     * @see: https://reactjs.org/docs/hooks-effect.html
     * Same as componentDidMount() hook because we pass an empty array [] as second argument,
     * this argument watches for changes in passed arguments
     * In componentDidMount we have access to window object elsewhere not always!!!
     */
    useEffect(() => {

      const isBrowser = typeof window !== 'undefined'
      if (!isBrowser) {
          return false
      }

      props.intlPreserve();

      // setHtmlElement(document.getElementsByTagName(`html`)[0]);

      // SHOP EXT START
      // @see: https://github.com/gatsbyjs/gatsby/issues/14480
      // const store = configureStore();
      // store.dispatch(setCartToken(localStorage.getItem('cartToken') || Math.random().toString(36).substr(2)))
      // store.dispatch(cartFetch());
      // SHOP EXT STOP

      const { node } = props;
      let nodeId = null;
      let productId = null;

      if (node) {
        nodeId = node.drupal_internal__nid || null;
        productId = node.drupal_internal__product_id || null;
      }

      if (typeof window.g_soft4NetFrontConfig === `undefined`) {
        loadScripts(nodeId, productId);
      } else {
        callScripts(nodeId, productId);
      }

      loadCommerceStoreOnlineData();

      // @see: https://fullstackheroes.com/react/exit-intent-react/
      const removeExitIntent = ExitIntent({
        threshold: 30,
        eventThrottle: 100,
        onExitIntent: () => {
// console.log(`setShowPopupExitIntent`)
          setShowPopupExitIntent(true)
        },
      })
      return () => {
        removeExitIntent()
      }

    }, []);

    // useLayoutEffect(() => {
    //   loadScripts();
    // })

    // @see: https://www.gatsbyjs.org/docs/use-static-query/
    const data = useStaticQuery(graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `)

    // const useDrupalBlockContentBasic_Soft4NetFooterContactDetails = useDrupalBlockContentBasic(1);
    // console.log(useDrupalBlockContentBasic_Soft4NetFooterContactDetails);

    //console.log(useDrupalInterfaceTranslation('@items enabled', {'@items': 12}))
    //console.log(useDrupalInterfaceTranslation('soft4cms_all_rights_reserved'));

    // console.log(props.location)

    const { node } = props;
    const className = props.className || false;

    let nodeId = null;
    let productId = null;
    let oWrapperMainClassNode = null;

    if (node) {
      nodeId = node.drupal_internal__nid || null;
      productId = node.drupal_internal__product_id || null;

      oWrapperMainClassNode = {
        [node.internal.type]: true,
        [`drupal_internal__nid-${node.drupal_internal__nid}`]: node.drupal_internal__nid,
        [`drupal_internal__product_id-${node.drupal_internal__product_id}`]: node.drupal_internal__product_id,
        [kebabCase(node.title)]: true,
      }
    }

    const oClassNamesHtml = classNames({
      [`accessibility-high-contrast`]: props.isHighContrastMode,
      [`accessibility-font-size-${props.fontSize}`]: true,
      [`has-top-announcement-bar`]: context.hasTopAnnouncementBar,
    });



    // // const $html = $(`html`);
    // const html = htmlElement;
    // if (html) {
    //   if (props.isHighContrastMode) {
    //     html.classList.add(`accessibility-high-contrast`);
    //   } else {
    //     html.classList.remove(`accessibility-high-contrast`);
    //   }
    //   // html.classList.toggle(`accessibility-high-contrast`);

    //   if (props.fontSize) {
    //     html.classList.forEach(className => {
    //       if (className.startsWith(`accessibility-font-size-`)) {
    //         html.classList.remove(className);
    //       }
    //     });
    //     html.classList.add(`accessibility-font-size-${props.fontSize}`);
    //   }

    //   if (context.hasTopAnnouncementBar) {
    //     html.classList.add(`has-top-announcement-bar`);
    //   } else {
    //     html.classList.remove(`has-top-announcement-bar`);
    //   }
    // }



    const oClassNamesBody = classNames({
      [`${useScreenSize()}`]: true,
    });

    const oWrapperMainClass = classNames({
      'wrapper-main': true,
      [intl.locale]: true,
      // [data.site.siteMetadata.title]: true,
      ...oWrapperMainClassNode,
      [className]: className,
    });

    // const ServiceCookieCompliance = loadable(() => import('./s4n/Service/cookieCompliance'));
    // const ServiceTawkTo = loadable(() => import('./s4n/Service/tawkTo'));
    // const ServiceFacebookBadge = loadable(() => import('./s4n/Service/facebookBadge'))
    // const ServiceScrollTop = loadable(() => import('./s4n/Service/scrollTop'));
    // const ServiceNewsletter = loadable(() => import('./s4n/Service/Newsletter'));

    const hasFooterContactForm = [
      // 1,  // Home
      // 15, // Products
      // 16, // Kontakt
      // 17, // Portfolio
    ].includes(Number(nodeId));

    return (

      // <Provider store={store}>
      //   <ConnectedRouter history={history}>
        <React.Fragment>

          {/* <!-- preloader start --> */}
            {/* <div className="preloader">
              <img src="/elite-shop/images/preloader.gif" alt="preloader" />
            </div> */}
          {/* <!-- preloader end --> */}

          <Helmet
            defer={false} // @see: https://www.gatsbyjs.com/plugins/gatsby-plugin-react-helmet/?=#titles-dont-appear-when-opening-in-the-background-while-using-gatsby-plugin-offline
            
            // @todo: this removes already set classes by 3rd party plugins!!!
            htmlAttributes={{
              // class: {
              //   // ...htmlAttrs.class,
              //   // ...htmlAttributes.class,
              //   oClassNamesHtml,
              // }
              class: oClassNamesHtml,
            }}

            bodyAttributes={{
                class: oClassNamesBody
            }}
          >
            {/* Added in src/components/seo.js because when redirection in gatsby-plugin-intl is enabled it won't pick up this meta tag!!! */}
            {/* <meta name="facebook-domain-verification" content="0uappgytr7ipauys0re25kdjmzhotu" /> */}
            {1 === Number(nodeId) &&
              <title>Decolly - ręcznie wyplatane wianki okolicznościowe</title>
            }
          </Helmet>

          <div 
            // className={cssModule.root}
            className={oWrapperMainClass}
          >


          {/* Acessibility */}
          <a id="top" className="sr-only sr-only-focusable" href="#content">
            <div className="container">
              <span className="skiplink-text">Skip to main content</span>
            </div>
          </a>



{/* SMOOTH_SCROLL START */}
{/* @see: https://github.com/idiotWu/smooth-scrollbar/tree/65085bf3e75e26a2804323b569c827d548c59900/docs */}
{/* <Scrollbar
  // damping={number}
  // thumbMinSize={number}
  // syncCallbacks={true}
  renderByPixels={true} // Render every frame in integer pixel values, set to true to improve scrolling performance.
  alwaysShowTracks={false}
  continuousScrolling={true} // Set to true to allow outer scrollbars continue scrolling when current scrollbar reaches edge.
  // wheelEventTarget={element}
  // plugins={object}
  // onScroll={func}

  ref={scrollbarRef}
> */}
{/* SMOOTH_SCROLL STOP */}



              <Header
// SMOOTH_SCROLL START
                // ref={scrollbarRef}
                // scrollbarRef={scrollbarRef}
// SMOOTH_SCROLL STOP
                nodeId={nodeId} 
                siteTitle={data.site.siteMetadata.title} 
              />

              {/* {`null` !== process.env.SOFT4NET_SHOP_PAGE_DRUPAL_ID && 
                <ShopCheckoutIframe />
              } */}

              {/* <YupSetLocale /> */}

              <div id={`content`} className="main-wrapper">
                <S4nLayoutBlock name="main" nodeId={nodeId} productId={productId} hasFooterContactForm={hasFooterContactForm}>
                    {children}
                </S4nLayoutBlock>

                <Footer 
                  nodeId={nodeId} 
                  hasFooterContactForm={hasFooterContactForm} 
                  commerceStoreOnlineData={reduxState.commerceStoreOnlineData}
                />

                {1 === Number(reduxState.commerceStoreOnlineData?.field_popup_exitintent_enabled) &&
                  <div className={cn({
                      'd-none': !showPopupExitIntent
                  })}>
                      <Popup 
                        className={`newsletter exit-intent`} 
                        cookieName="blPopupExitIntentDisabled"
                      >
                          <section className="newsletter-1">
                              <div className="container px-0">
                                  <div className="row no-gutters">
                                      <div className="col-xl-5 d-none d-xl-block">
                                          <ImageS4n src={`custom/page/home/blog.jpg`} _className="img-fluid rounded w-100" _maxWidth="300px" _maxHeight="5rem" />
                                      </div>
                                      <div className="col col-xl-7">
                                          <div className="wrapper">
                                              <div className="row">
                                                  <div className="col benefits">
                                                      <FormattedHTMLMessage 
                                                          id={`soft4net_exitintent_popup_message`}
                                                      />
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </section>
                      </Popup>
                  </div>
                }

          </div>



{/* SMOOTH_SCROLL START */}
{/* </Scrollbar> */}
{/* SMOOTH_SCROLL STOP */}



            {false && 
              <React.Fragment>
                {/* <ServiceCookieCompliance /> */}
                {/* <ServiceTawkTo serviceId="serviceId" /> */}
                {/* <ServiceScrollTop /> */}
                {/* <ServiceNewsletter /> */}
                {/* <ServiceFacebookBadge serviceId="https://www.facebook.com/soft4net/" serviceName="soft4net.io" /> */}
              </React.Fragment>
            }

          </div>

{/* <pre>
  {JSON.stringify(reduxState, null, 4)}
</pre> */}

        </React.Fragment>

      //   </ConnectedRouter>
      // </Provider>

    )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

// @see: https://gatsbyguides.com/tutorial/authenticate-users-oauth
// @see: https://www.gatsbyjs.org/tutorial/authentication-tutorial/
// Initialize a new drupalOauth client which we can use to seed the context 
// provider.
const drupalOauthClient = new drupalOauth({
  drupal_root: process.env.SOFT4NET_SOURCE_BASE_URL,
  client_id: process.env.SOFT4NET_DRUPAL_OAUTH_CLIENT_ID,
  client_secret: process.env.SOFT4NET_DRUPAL_OAUTH_CLIENT_SECRET,
})

const mapStateToProps = state => ({
  // user: state.shopReducers.user,
  fontSize: state.fontSize.fontSize,
  isHighContrastMode: state.highContrast.isHighContrastMode,
})

const mapDispatchToProps = dispatch => ({
  intlPreserve: (intl) => dispatch(intlPreserve(intl)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withDrupalOauthProvider(drupalOauthClient, Layout));
