/**
 * Just view/template component, no model/data logic!
 */
import React from "react"
import Img from 'gatsby-image'
import Image from '../../image'

// s4n
import { useIntl, Link } from "gatsby-plugin-intl"
import GeneralUtils from './../../../utils/common'
import kebabCase from "lodash.kebabcase"
// import "./../../assets/sass/templates/node/_blog.scss"
import ReadMore from './../ReadMore'



const ComponentStateless = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { node, linkTranslation, bootstrapColClass } = props; // alias t === translate function

    const returnOnlyLink = props.returnOnlyLink || false;
    const anchorClass = props.anchorClass || false;
    const disableImages = props.disableImages || false;
    const disableSummary = props.disableSummary || false;
    const disableButtonMore = props.disableButtonMore || false;

    let img = node.relationships.field_image ? node.relationships.field_image.localFile.childImageSharp.fluid : null;
    if (!img) {
      img = node.relationships.field_media_image && node.relationships.field_media_image.relationships.field_media_image ? 
        node.relationships.field_media_image.relationships.field_media_image.localFile.childImageSharp.fluid : null;
    }

    if (returnOnlyLink) {
      return <Link to={ node.path.alias } className={anchorClass}>{ node.title }</Link>
    }

    return (
        <React.Fragment>
			<div key={`blog-item-${node.id}`} className={`col-lg-6`}>
				<div className={`item media-text bg-grey`}>
					<div className={`row`}>
						{!disableImages && 
							<div className={`col-12 col-xl-6`}>
								<Link to={node.path.alias} title={node.title} className="image">
									{ img ? <Img fluid={img} className="img-fluid" alt={node.title} /> : <Image /> }
								</Link>
							</div>
						}
						<div className={`col-12 col-xl-6`}>
							<div className={`inner`}>
								<div className={`circle half bottom bg-white`}>{GeneralUtils.dateFormat(node.created, null, intl.locale)}</div>
								<div className={`title`}>
									<Link to={node.path.alias} title={node.title}>{node.title}</Link>
								</div>
							
								{!disableSummary && node.field_summary && 
									<div className={`content`} dangerouslySetInnerHTML={{ __html: node.field_summary }}></div>
								}
								{/* <div dangerouslySetInnerHTML={{ __html: node.body ? node.body.value.split(' ').splice(0, 50).join(' ') + '...' : '' }}></div> */}
								{/* <div dangerouslySetInnerHTML={{ __html: node.body ? node.body.value : '' }}></div> */}

								{!disableButtonMore && 
									<ReadMore to={node.path.alias} title={node.title}>{ t({ id: "soft4net_more" }) }</ReadMore>
								}

							</div>
						</div>
					</div>
				</div>
			</div>

			{false &&
				<div key={`blog-item-${node.id}`} className="col-lg-4 col-md-6 mb-4">
					<div className="post">
						{!disableImages && 
							<div className="post-thumb">
								<Link to={node.path.alias} title={node.title} className="image">
									{ img ? <Img fluid={img} className="img-fluid" alt={node.title} /> : <Image /> }
								</Link>
							</div>
						}
						<h3 className="post-title">
							<Link to={node.path.alias} title={node.title}>{node.title}</Link>
						</h3>
						<div className="post-meta">
							<ul>
								<li>
									<i className="ti-calendar"></i>{` `}
									{/* {node.created} */}
									{GeneralUtils.dateFormat(node.created, null, intl.locale)}
								</li>
								{/* <li>
									<i className="ti-user"></i>{` `}POSTED BY ADMIN
								</li> */}
								{/* tags */}
								{node.relationships.field_tags && !!node.relationships.field_tags.length && 
									<li>
										<i className="ti-tag"></i>{` `}
										{/* { t({ id: "soft4net_lists_division_tags" }) }: */}
										{node.relationships.field_tags.map((tag, i) => {
											const isLastItem = node.relationships.field_tags.length === i + 1;

											return (
												<React.Fragment>
													<Link to={`/${linkTranslation}/tag/${kebabCase(tag.name)}`} title={tag.name} className={``}>{tag.name}</Link>
													{ isLastItem ? `` : `, ` }
												</React.Fragment>
											)
										})}
									</li>
								}
								{/* <li>
									<a href=""><i className="ti-comments"></i>{` `}4 COMMENTS</a>
								</li> */}
							</ul>
						</div>
						<div className="post-content">
							{!disableSummary && node.field_summary && <p dangerouslySetInnerHTML={{ __html: node.field_summary }}></p>}
							{/* <div dangerouslySetInnerHTML={{ __html: node.body ? node.body.value.split(' ').splice(0, 50).join(' ') + '...' : '' }}></div> */}
							{/* <div dangerouslySetInnerHTML={{ __html: node.body ? node.body.value : '' }}></div> */}
							{!disableButtonMore && 
								<Link to={node.path.alias} title={node.title} className="btn btn-primary btn-sm">
									{ t({ id: "soft4net_more" }) }
								</Link>
							}
						</div>
					</div>
				</div>
			}
        </React.Fragment>
      )
  }
  
  export default ComponentStateless