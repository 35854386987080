import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import Link from './../Link'

// import styles from './../../../assets/sass/components/ReadMore/_index.module.scss'

export default (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { to, title, onClick } = props;
    const children = props.children ? props.children : <>&nbsp;</>;
    const className = props.className ? props.className : `white`;

    let hasText = false;
    if (props.children) {
        hasText = true;
    }

    let innerAttributes = null;
    let linkElement = <Link to={to} title={title}>{children}</Link>

    if (onClick) {
        linkElement = <a href="#" title={title}>{children}</a>
        innerAttributes = {
            onClick: (e) => {e.preventDefault(); onClick();},
        }
    }

    return (
        <React.Fragment>
            <div 
                // className={styles.container}
                className={`read-more arrow ${className} hvr-forward`}
            >
                <div className={`inner${hasText ? ` has-text` : ``}`} {...innerAttributes}>
                    {linkElement}
                </div>
            </div>
        </React.Fragment>
    )
}