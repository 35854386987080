import React from "react"
// import PropTypes from "prop-types"
import Img from "gatsby-image"
import { useIntl, navigate } from "gatsby-plugin-intl"

// s4n
// @see: https://github.com/gatsbyjs/gatsby/issues/1875
// import Link from "./Link"
// import { Location } from '@reach/router'
// import { globalHistory } from "@reach/router"
// import MenuUtils from "./../s4n/Menu/utils"
// import { useParseInlineImages } from '../../hooks/drupal/parseInlineImages'
import loadable from '@loadable/component'
import Preloader from '../s4n/Preloader'
// import FormBasic from "./../forms/basic"
// import FormBasicMaterialUiExample1 from "./../forms/basic.material-ui.example-1"
// import FormBasicMaterialUiExample2 from "./../forms/basic.material-ui.example-2"
// import FormBasicMaterialUiExample3 from "./../forms/basic.material-ui.example-3"
// import UseDrupalBlockContentBasic from './../../hooks/drupal/blockContentBasic'
// import FormContactS4n from "./../forms/webform/contact_s4n"
// import FormBasicMaterialUi1 from "./../forms/basic.material-ui.test"
// import FormFormikJsonSchema from "./../forms/formik-json-schema"
// import Harmonogram from './../../components/s4n/Harmonogram/harmonogram'
// import Shop from './Shop/index'

// import FormikJsonSchema from "./../forms/formik-json-schema"
// import ReactJsonSchema from "./../forms/react-jsonschema-form"
// import CircleInteractive from "./../s4n/CircleInteractive"

// import { Accordion, Card } from 'react-bootstrap' // TEST for src/assets/sass/fix/bootstrap/_transitions.scss

import ImageS4n from './image'
import Breadcrumbs from './Menu/Breadcrumbs'
import PromotedFrontPage from './../s4n/Promoted/FrontPage'
import GoogleMap from './../s4n/GoogleMap'
import FormContactS4n from './../forms/standalone/contact_s4n'

import Reviews from './../s4n/Reviews'
import ReadMore from './../s4n/ReadMore'
import UseDrupalBlockContentBasic from './../../hooks/drupal/blockContentBasic'
import Link from './Link'


const ComponentFunctional = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { children, name, nodeId, productId, hasFooterContactForm } = props;

    // console.log(props.location)
    // const path = globalHistory.location.pathname;

    // Loadable components START
    // let UseDrupalBlockContentBasic = null;
    let Subpages = null;
    // let Portfolio = null;
    // let GoogleMap = null;
    // let Harmonogram = null;

    // let FormContactS4n = true === hasFooterContactForm ? loadable(() => import(`./../forms/standalone/contact_s4n`), {fallback: <Preloader />}) : null;

    switch (nodeId) {
        // case 1: // Home
        case 14: // Services
            Subpages = loadable(() => import(`./../s4n/Menu/Subpages`));
            break;
        case 16: // Contact
            // UseDrupalBlockContentBasic = loadable(() => import(`./../../hooks/drupal/blockContentBasic`), {fallback: <Preloader />})
            // GoogleMap = loadable(() => import(`./../s4n/GoogleMap`), {fallback: <Preloader />})
            break;
        // case 17: // Portfolio
        //     Portfolio = loadable(() => import(`./../s4n/Portfolio`), {fallback: <Preloader />})
        //     break;
        // case 871:
        //     Harmonogram = loadable(() => import(`./../../components/s4n/Harmonogram/harmonogram`), {fallback: <Preloader />})
        //     break;
    }
    // const FormContactS4n = loadable(() => import(`./../forms/webform/contact_s4n`))
    // const UseDrupalBlockContentBasic = loadable(() => import(`./../../hooks/drupal/blockContentBasic`))
    // const Harmonogram = loadable(() => import(`./../../components/s4n/Harmonogram/harmonogram`))
    // Loadable components STOP
    
    return (
        <React.Fragment>
            {/* {(() => {
                return (
                    <React.Fragment>
                        This was rendered!
                    </React.Fragment>
                )
            })()} */}

            {/* !!! Children !!! */}
            {/* <div className="container-fluid p-0">
                <div className="row no-gutters">
                    <div className="col"> */}

                        {/* {![
                            Number(process.env.SOFT4NET_HOME_PAGE_DRUPAL_ID),
                            Number(process.env.SOFT4NET_SHOP_PAGE_DRUPAL_ID),
                        ].includes(Number(nodeId)) && 
                            // <!-- breadcrumb -->
                            <nav className="bg-gray py-3">
                                <div className="container">
                                    <Breadcrumbs nid={nodeId} />
                                </div>
                            </nav>
                            // <!-- /breadcrumb -->
                        } */}

                        { children }

                    {/* </div>
                </div>
            </div> */}

            {/* Harmonogram (Szkolenia) */}
            {/* {871 === nodeId && 
                <React.Fragment>
                    <Harmonogram />
                </React.Fragment>
            } */}

            {/* Services */}
            {/* {14 === nodeId && 
                <React.Fragment>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <Subpages nid={nodeId} template={`service`} disableHeader={true} />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            } */}

            {/* Portfolio */}
            {/* {17 === nodeId && 
                <React.Fragment>
                    <Portfolio />
                </React.Fragment>
            } */}

            {/* Contact form */}
            {hasFooterContactForm && 
                <React.Fragment>
                    {16 === nodeId && 
                        <GoogleMap />
                    }
                    <section id="contact" className="section section-on-footer s4n-footer-contact" 
                        // data-background="images/backgrounds/bg-dots.png"
                    >
                        <div className="container">
                            <div className="row">
                                {16 !== nodeId && 
                                    <div className="col-12 text-center">
                                        <h2 className="section-title">{t({ id: `soft4net_footer_contact_title` })}</h2>
                                    </div>
                                }

                                <div className="col-lg-8 mx-auto">
                                    <div className="bg-white rounded text-center p-5 shadow-down w-100">
                                        <h4 className="mb-80">
                                            {16 === nodeId ? t({ id: `soft4net_footer_contact_form_title_alt` }) : t({ id: `soft4net_footer_contact_form_title` })}
                                        </h4>
                                        <FormContactS4n />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* <div className="container-fluid p-0">
                        <div className="row no-gutters">
                            <div className="col">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2561.6138153517677!2d19.923515616158642!3d50.05606527942285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47165b7483d44267%3A0x8c3eb47731cb486c!2sHeuresis+Sp.+Z+O.o.!5e0!3m2!1sen!2spl!4v1561407705962!5m2!1sen!2spl" width="600" height="450" frameborder="0" style={{border: 0}} allowfullscreen></iframe>
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="col-xs-12 col-md-6 order-md-2">
                        <div className="wp-block-soft4net-container-block container-fluid bg-orange mt-1 py-3">
                            <UseDrupalBlockContentBasic blockId="1" />
                        </div>
                    </div> */}
                        
                    {/* TEST for src/assets/sass/fix/bootstrap/_transitions.scss START */}
                    {/* <Accordion _defaultActiveKey={0}>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey={0}>
                                <strong>Formularz kontaktowy</strong>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={0}>
                                <Card.Body>
                                    <FormContactS4n />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    <div style={{display: `none`}} onClick={(e) => e.target.style.display = 'block'}>
                        <FormContactS4n />
                    </div>
                    <div style={{visibility: `hidden`}} onClick={(e) => e.target.style.visibility = 'visible'}>
                        <FormContactS4n />
                    </div> */}
                    {/* TEST for src/assets/sass/fix/bootstrap/_transitions.scss STOP */}

                </React.Fragment>
            }

            {/* {MenuUtils.isCurrentUri('internal:/blog') && 
                <Blog />
            } */}

            {/* {[1062, 1083].includes(nodeId) && 
                <React.Fragment>
                    <Shop />
                </React.Fragment>
            } */}

            {/* {<Location>
                {({ location }) => {
                    // console.log(JSON.stringify(location, null, 4))
                    return (
                        // @see: https://github.com/gatsbyjs/gatsby/issues/8787
                        <div className="container">
                            { '/pl#contact' === location.pathname + location.hash && <FormBasic /> }
                            { '/pl#contact' === location.pathname + location.hash && <FormBasicMaterialUi /> }
                        </div>
                    )
                }}
            </Location>} */}





            <div className={`container`}>
                <div className={`decoration-stripe py-5`}></div>

                <div className={`row _items`}>
                    {(() => {
                        const translation = {
                            link_wreaths_promotions: {
                                pl: `/wianki/promocje`,
                                en: `/wreaths/promotions`,
                                de: `/wreaths/promotions`,
                            },
                            link_wreaths_news: {
                                pl: `/wianki/nowosci`,
                                en: `/wreaths/news`,
                                de: `/wreaths/news`,
                            }
                        }

                        return (
                            <React.Fragment>
                                <div className={`col-lg-6`}>
                                    <div className={`link-centered mb-5 mb-lg-0`}>
                                        <Link to={translation.link_wreaths_promotions[intl.locale]}>
                                            <ImageS4n src={`custom/page/home/promotions.jpg`} alt={ t({ id: `soft4net_promotions` }) } _className="img-fluid rounded w-100" _maxWidth="300px" _maxHeight="5rem" />
                                            <h3>{ t({ id: `soft4net_promotions` }) }</h3>
                                        </Link>
                                    </div>
                                    {/* <div onClick={() => navigate(translation.link_wreaths_promotions[intl.locale])} className={`item cloud rounded decorated promotions bg-yellow`}>
                                        <div className={`title`}>
                                            <ReadMore to={translation.link_wreaths_promotions[intl.locale]} title={ t({ id: `soft4net_promotions` }) }>{ t({ id: `soft4net_promotions` }) }</ReadMore>
                                        </div>
                                    </div> */}
                                </div>
                                <div className={`col-lg-6`}>
                                    <div className={`link-centered`}>
                                        <Link to={translation.link_wreaths_news[intl.locale]}>
                                            <ImageS4n src={`custom/page/home/news.jpg`} alt={ t({ id: `soft4net_news` }) } _className="img-fluid rounded w-100" _maxWidth="300px" _maxHeight="5rem" />
                                            <h3>{ t({ id: `soft4net_news` }) }</h3>
                                        </Link>
                                    </div>
                                    {/* <div onClick={() => navigate(translation.link_wreaths_news[intl.locale])} className={`item cloud rounded decorated news bg-green`}>
                                        <div className={`title`}>
                                            <ReadMore to={translation.link_wreaths_news[intl.locale]} title={ t({ id: `soft4net_news` }) }>{ t({ id: `soft4net_news` }) }</ReadMore>
                                        </div>
                                    </div> */}
                                </div>
                            </React.Fragment>
                        )
                    })()}
                </div>

                <div className={`decoration-stripe py-5`}></div>

                {Number(process.env.SOFT4NET_HOME_PAGE_DRUPAL_ID) === nodeId && 
                    <React.Fragment>
                        <div className={`row custom-left-image-right-text`}>
                            <div className={`col-lg-4 col-xl-6`}>
                                <ImageS4n src={`custom/page/home/2.jpeg`} alt={``} _className="img-fluid rounded w-100" _maxWidth="300px" _maxHeight="5rem" />
                            </div>                    
                            <div className={`col-lg-8 col-xl-6`}>
                                <h3>Wianki Decolly</h3>
                                <p>Decolly tworzy unikalne i wysokiej jakości wianki, które wprowadzą niesamowity klimat do Twojego wnętrza. Cechy naszych produktów:</p>

                                <div className={`row`}>
                                    <div className={`col-auto`}>
                                        {/* <i className="fas fa-palette icon-1"></i> */}
                                        <i className="ti-palette icon-1"></i>
                                    </div>
                                    <div className={`col`}>
                                        <strong>Oryginalny projekt</strong>
                                        <p>Każdy z wianków Decolly to efekt oryginalnego projektu — nie ma drugiego takiego samego. Projektując, myślimy zarówno o oryginalnym wyglądzie, jak również bierzemy pod uwagę to, jaki klimat mają budować oraz jakim emocjom towarzyszą.</p>
                                    </div>
                                </div>
                                <div className={`row`}>
                                    <div className={`col-auto`}>
                                        <i className="fas fa-signature icon-2"></i>
                                    </div>
                                    <div className={`col`}>
                                        <strong>Dbałość o detale</strong>
                                        <p>Wykonywane przez nas wianki charakteryzują się szczególną dbałością o detale. Materiały, z których je tworzymy, są przez nas skrupulatnie dobierane pod kątem wyglądu czy wytrzymałości. Szczególną wagę przykładamy do jakości poszczególnych elementów, bo to one składają się na końcowy efekt naszych wianków.</p>
                                    </div>
                                </div>
                                <div className={`row`}>
                                    <div className={`col-auto`}>
                                        <i className="fab fa-skyatlas icon-3"></i>
                                    </div>
                                    <div className={`col`}>
                                        <strong>Pasja wykonania</strong>
                                        <p>Mamy wykształcenie artystyczne, które przekładamy na to, co robimy. Jesteśmy pasjonatami. Tworzymy, nie pracujemy.</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className={`decoration-stripe pt-0`}></div>
                    </React.Fragment>
                }

                <Reviews />
                {/* <Testimonials /> */}

                {Number(process.env.SOFT4NET_HOME_PAGE_DRUPAL_ID) === nodeId && 
                    <React.Fragment>

                        <div className={`row items`}>
                            <div className={`col-lg-6`}>
                                <div className={`item media-text bg-blue    mb-3`}>
                                    <div className={`row`}>
                                        <div className={`col-12 col-xl-6`}>
                                            <ImageS4n src={`custom/page/home/decolly-wizytowka.jpg`} alt={``} _className="img-fluid rounded w-100" _maxWidth="300px" _maxHeight="5rem" />
                                        </div>
                                        <div className={`col-12 col-xl-6`}>
                                            <div className={`inner`}>
                                                <div className={`title`}>O Decolly</div>
                                                <div className={`content`}>
                                                    Decolly to miejsce, gdzie pasja do rękodzieła przeplata się z otaczającą naturą. Każdego dnia w naszej pracowni powstają unikalne wianki, wykonane według naszego autorskiego projektu. W efekcie nie ma drugiego takiego samego. Tworzone przez nas wianki i okolicznościowe ozdoby wyróżniają się unikalnym wzornictwem oraz szczególną dbałością o detale.
                                                </div>
                                                <ReadMore className={`black`} to={28}>O Decolly</ReadMore>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`col-lg-6`}>
                                <div className={`item media-text bg-grey    mb-3`}>
                                    <div className={`row`}>
                                        <div className={`col-12 col-xl-6`}>
                                            <ImageS4n src={`custom/page/home/blog.jpg`} alt={``} _className="img-fluid rounded w-100" _maxWidth="300px" _maxHeight="5rem" />
                                        </div>
                                        <div className={`col-12 col-xl-6`}>
                                            <div className={`inner`}>
                                                <div className={`circle half bottom bg-white`}>17.12.2019</div>
                                                <div className={`title`}>Blog</div>
                                                <div className={`content`}>
                                                    ŚWIĄTECZNE DEKOROWANIE DOMU<br />
                                                    Śnieżna biel, nastrojowe oświetlenie, a może gorąca czekolada i zapach cynamonu? Niech nasz dom wypełnią okolicznościowe wianki z zimozielonych gałązek oraz szyszek. Zima to czas, w którym drzwi domu, ściany nad kominkami czy okna zdobione są przez dekoracje bożonarodzeniowe. 
                                                </div>
                                                <ReadMore to={`/blog`}>Blog</ReadMore>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={`row`}>
                            <div className={`col py-3`}>
                                <div className={`wrapper-home-seo-content`}>
                                    <UseDrupalBlockContentBasic blockId={67} />
                                    <a className="show-more" data-toggle="collapse" href="#collapse-home-content" role="button" aria-expanded="false" aria-controls="collapse-home-content">
                                        {/* { t({ id: `soft4net_more` }) } */}
                                    </a>
                                </div>
                            </div>
                        </div>

                    </React.Fragment>
                }

            </div>



        </React.Fragment>
    )
}

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default ComponentFunctional