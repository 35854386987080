import React from "react"
//import { Link } from "gatsby"

// s4n
// import "./../assets/sass/pages/_blog.scss"
import { injectIntl } from "gatsby-plugin-intl"
import Link from './../../Link' // extends Link from gatsby-plugin-intl
import executeQuery from "./query"
import CollectionDataUtils from "./../../../../utils/Collection/data"
import BlogCard from './../../Blog/card'



const ComponentStateless = (props) => {
  const { intl, limit } = props
  const t = intl.formatMessage
  const dataToConvert = executeQuery()

  // mege all results keys into one array with main key => "index" so we can pass this to CollectionDataUtils.filterData
  const data = { index: { edges: [] } };
  Object.keys(dataToConvert).map(allNodeType => {
    const allNodeTypeEdges = dataToConvert[allNodeType].edges;
    allNodeTypeEdges.map(edge => {
      data.index.edges.push(edge);
    });
  });

  // prepare data collection, without duplications in one language because of Drupal translation fallback strategy if translation does not exists
  const aDataCollectionTranslated = CollectionDataUtils.filterData(data.index.edges, intl.locale);

  // console.log(JSON.stringify(dataToConvert, null, 4))
  // console.log(JSON.stringify(data.index.edges, null, 4))
  // console.log(JSON.stringify(aDataCollectionTranslated, null, 4))

  const disableWrapp = props.disableWrapp || false;
  const CustomWrapper = props.CustomWrapper || false;
  const disableHeader = props.disableHeader || false;
  const returnOnlyLink = props.returnOnlyLink || false;
  const disableButton = props.disableButton || false;

  const aReturnReact = aDataCollectionTranslated.map(({ node }, index) => {
    if (index >= limit) {
      return null;
    }

    const ReactBlogCard = <BlogCard key={`promoted-front-page-item-${node.id}`} {...props} node={node} />

    if (CustomWrapper) {
      return <CustomWrapper>{ReactBlogCard}</CustomWrapper>
    }

    return ReactBlogCard;
  });

  if (disableWrapp) {
    return aReturnReact;
  }

  return (
    <React.Fragment>
      <section className="section s4n-frontpage">
        <div className="container">
          {!disableHeader && 
            <div className="row">
              <div className="col text-center">
                <h2 className="section-title">Blog</h2>
              </div>
            </div>
          }
          <div className="row">
            {/* @see: https://hackernoon.com/5-techniques-to-iterate-over-javascript-object-entries-and-their-performance-6602dcb708a8 */}
            {aReturnReact}
          </div>
          {!returnOnlyLink && !disableButton && 
            <div className="row">
              <div className="col text-center">
                <Link to={`/blog`} title={`Blog`} className="btn btn-primary">Blog</Link>
              </div>
            </div>
          }
        </div>
      </section>
    </React.Fragment>
  )
}

export default injectIntl(ComponentStateless)