/*eslint no-unused-vars: "off"*/

import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {TextField, Input, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@material-ui/core';

import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';

// s4n 
import { useIntl } from "gatsby-plugin-intl"
import Preloader from './../../s4n/Preloader'
import classNames from 'classnames';
import Yup from './../../s4n/Yup';
import FormHelper from './../../s4n/Form/standalone'
// import { DisplayFormikState } from "./../formikHelper"


/*
const styles = theme => ({
  // paper: {
  //   marginTop: theme.spacing.unit * 8,
  //   display: "flex",
  //   flexDirection: "column",
  //   alignItems: "center",
  //   padding: `${theme.spacing.unit * 5}px ${theme.spacing.unit * 5}px ${theme
  //     .spacing.unit * 5}px`
  // },
  success: {
    backgrounColor: "#4caf50",
    borderColor: "#4caf50",
  }
});
*/



// @see: https://stackoverflow.com/questions/49535551/change-secondary-and-primary-colors-in-material-ui
// Or Create your Own theme:
// const theme = createMuiTheme({
//   palette: {
//     primary: {
//       main: ConfigSharedColors.primary
//     },
//     secondary: {
//       main: ConfigSharedColors.secondary
//     }
//   }
// });

const styles = theme => ({
  root: {
    color: "#FFFFFF",
  },
  textField: {
      'border-radius': `4rem`,
      border: `1px solid #565656`,
      width: `75% !important`,
      // height: `2.5rem`,
      // 'padding': `0 1.25rem 0.5rem`,
      '& label': {
        'padding': `0.5rem 1.5rem`,
        color: '#c5c5c5',
      },
      '& input': {
        // 'border-radius': `2rem`,
        // border: `1px solid #565656`,
        height: `auto`,
        'padding': `1rem 1.25rem`,
      },
  },
    // input: {
    //   'border-radius': `2rem`,
    //   border: `1px solid #565656`,
    //   // height: `2.5rem`,
    //   'padding-top': `0.5rem`,
    //   'padding-bottom': `0.5rem`,
    // },
  textArea: {
    'border-radius': `2rem`,
    border: `1px solid #565656`,
    '& label': {
      'padding': `0.5rem 1.5rem`,
      color: '#c5c5c5',
    },
    '& textarea': {
      // 'border-radius': `2rem`,
      // border: `1px solid #565656`,
      // height: `auto`,
      'padding': `1rem 1.25rem`,
    },
  },
  formButtonClass: {
    // paddingLeft: `1rem`,
    // paddingRight: `1rem`,
    // height: `2.4rem`,
    // fontSize: `0.75rem`,
    // borderRadius: `0`,
    // backgroundColor: ConfigSharedColors.primary,
    // [`:hover`]: {
    //   backgroundColor: ConfigSharedColors.primary,
    // }
  },
});



class Thumb extends React.Component {
  state = {
    loading: false,
    thumb: undefined,
  };

  componentWillReceiveProps(nextProps) {
    if (!nextProps.file) { return; }

    this.setState({ loading: true }, () => {
      let reader = new FileReader();

      reader.onloadend = () => {
        this.setState({ loading: false, thumb: reader.result });
      };

      reader.readAsDataURL(nextProps.file);
    });
  }

  render() {
    const { file } = this.props;
    const { loading, thumb } = this.state;

    if (!file) { return null; }

    if (loading) { return <p>loading...</p>; }

    return (<img src={thumb}
      alt={file.name}
      className="img-thumbnail mt-2"
      height={200}
      width={200} />);
  }
}



const FormStateless = (props) => {
  const intl = useIntl();
  const t = intl.formatMessage;

  const { classes, addCompanyField } = props;

  const [formStatus, setFormStatus] = useState({
    success: null,
    class: null,
    message: null,
  });

  const [state, setState] = useState({
    loading: false,
    error: false,
    formSchema: null,
  });

  const formSchema = {}

  let initialValues = {
    subject: `Wiadomość ze strony ${process.env.SOFT4NET_STATIC_BASE_URL}`,
    name: '',
    company: '',
    email: '',
    phone: '',
    message: '',
    file: null,
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    company: Yup.string().required(),
    email: Yup.string().email().required(),
    // phone: Yup.string().required(),
    message: Yup.string().required(),
    // file: yup.mixed().required(),
  })

  let fileElementName = 'file';

  // Set data for FormHelper START
  // set form ID!!!
  initialValues.webform_id = 'contact_s4n';
  // oFormHelper.formEndpoint = '';

  const oFormHelper = new FormHelper(intl);
  
  oFormHelper.t = t;
  oFormHelper.setFormStatus = setFormStatus;
  oFormHelper.state = state;
  oFormHelper.setState = setState;
  // Set data for FormHelper STOP

  /**
   * @see: https://reactjs.org/docs/hooks-effect.html
   * Same as componentDidMount() hook because we pass an empty array [] as second argument,
   * this argument watches for changes in passed arguments
   * In componentDidMount we have access to window object elsewhere not always!!!
   */
  // useEffect(() => {
  //   // Form schema does not contain markup elements!
  //   oFormHelper.getFormSchema(initialValues.webform_id);
  // }, []);



  return (
    <React.Fragment>
      {oFormHelper.state.loading ? // or state.loading this is a reference
        <Preloader /> : 
        <Formik
          initialValues={initialValues}
          onSubmit={oFormHelper.onSubmit}
          validationSchema={validationSchema}
        >
          {(props) => {
            const {
              values,
              errors,
              status,
              touched,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              setFieldValue,
            } = props;

            // console.log(status)

            const formStatusClass = classNames({
              // "mb-0": true,
              "alert": true,
              "d-none": null === formStatus.class,
              [`alert-${formStatus.class}`]: true,
            });

            const TextFieldPropsExtra = {
              InputProps: {
                disableUnderline: true,
                // 'class': 'form-control px-0 mb-4'
              },
              // InputLabelProps: {
              //   shrink: false
              // }
            }

            return (
              <Form 
                  onSubmit={handleSubmit} 
                  className={initialValues.webform_id}
              >
                  <div className="row flex-column">
                      <div className="col-12">
                          {/* <input type="text" id="name" name="name" placeholder="Full Name" className="form-control px-0 mb-4" /> */}
                          <TextField 
                            id={`${initialValues.webform_id}_name`}
                            label={ oFormHelper.getFieldTitle(`name`) }
                            name="name"
                            error={errors.name && touched.name}
                            className={classes.textField}
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={(errors.name && touched.name) && errors.name}
                            margin="normal"

                            {...TextFieldPropsExtra}
                          />
                      </div>

                      {addCompanyField && 
                        <div className="col-12">
                            {/* <input type="text" id="company" name="company" placeholder="Full Company" className="form-control px-0 mb-4" /> */}
                            <TextField 
                              id={`${initialValues.webform_id}_company`}
                              label={ oFormHelper.getFieldTitle(`company`) }
                              name="company"
                              error={errors.company && touched.company}
                              className={classes.textField}
                              value={values.company}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              helperText={(errors.company && touched.company) && errors.company}
                              margin="normal"

                              {...TextFieldPropsExtra}
                            />
                        </div>
                      }

                      <div className="col-12">
                          {/* <input type="email" id="email" name="email" placeholder="Email Address" className="form-control px-0 mb-4" /> */}
                          <TextField
                            id={`${initialValues.webform_id}_email`}
                            label={ oFormHelper.getFieldTitle(`email`) }
                            name="email"
                            error={errors.email && touched.email}
                            className={classes.textField}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={(errors.email && touched.email) && errors.email}
                            margin="normal"

                            {...TextFieldPropsExtra}
                          />
                      </div>
                      <div className="col-12">
                          {/* <input type="phone" id="phone" name="phone" placeholder="Phone Number" className="form-control px-0 mb-4" /> */}
                          <TextField
                            id={`${initialValues.webform_id}_phone`}
                            label={ oFormHelper.getFieldTitle(`phone`) }
                            name="phone"
                            error={errors.phone && touched.phone}
                            className={classes.textField}
                            value={values.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={(errors.phone && touched.phone) && errors.phone}
                            margin="normal"

                            {...TextFieldPropsExtra}
                          />
                      </div>
                      <div className="col-12">
                          {/* <textarea name="message" id="message" className="form-control px-0 mb-4" placeholder="Type Message Here"></textarea> */}
                          <TextField
                            id={`${initialValues.webform_id}_message`}
                            label={ oFormHelper.getFieldTitle(`message`) }
                            name="message"
                            error={errors.message && touched.message}
                            className={classes.textArea}
                            value={values.message}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={(errors.message && touched.message) && errors.message}
                            margin="normal"

                            // display as textarea
                            multiline={true}
                            rows="5"
                            rowsMax="5"

                            {...TextFieldPropsExtra}
                          />
                      </div>
                      <div className="col-12">
                          <div className="row input-send">
                              <div className="col-6">

                                  {/* <label for="file">File upload</label>
                                  <input id="file" name="file" type="file" onChange={(event) => {
                                    setFieldValue("file", event.currentTarget.files[0]);
                                  }} className="form-control" />
                                  <Thumb file={values.file} /> */}

                                  {/* @see: https://github.com/mui-org/material-ui/issues/9716 */}
                                  <Input ___accept="image/*" id={fileElementName} name={fileElementName} type="file" className="d-none form-control" onChange={(event) => setFieldValue(fileElementName, event.currentTarget.files[0])} />
                                  <label htmlFor={fileElementName}>
                                    {/* <Button variant="contained" color="primary" component="span" className={['orange', 'choose-file']}> */}
                                      {values[fileElementName] ? 
                                        values[fileElementName].name : 
                                        <React.Fragment>
                                          <p className={`upload-file`}>{t({ id: "soft4net_form_action_choose_file" })}</p>
                                        </React.Fragment>
                                      }
                                    {/* </Button> */}
                                  </label>

                                  {errors[fileElementName] && 
                                  // touched[fileElementName] &&
                                    <p className="MuiFormHelperText-root Mui-error">{errors[fileElementName]}</p>
                                  }

                              </div>
                              <div className="col-6">
                                  {/* <button className="btn btn-primary w-100">Send</button> */}
                                  <DialogActions>
                                    {/* <Button
                                      type="button"
                                      color="secondary"
                                      variant="contained"
                                      className="outline"
                                      onClick={handleReset}
                                      disabled={!dirty || isSubmitting}
                                    >{ t({ id: "soft4net_form_action_reset" }) }</Button> */}

                                    {isSubmitting ? 
                                      <CircularProgress /> : 
                                      <Button 
                                        type="submit" 
                                        variant="contained" 
                                        color="primary"
                                        className={formStatus && formStatus.success ? `btn btn-primary green` : `btn btn-primary`}
                                        disabled={isSubmitting}>{ t({ id: "soft4net_form_action_submit" }) }</Button>
                                    }

                                  </DialogActions>
                              </div>
                          </div>
                      </div>
                  </div>

                  {/* Form status messages */}
                  <div className="row form-status">
                    <div className="col">
                      <div dangerouslySetInnerHTML={{ __html: formStatus.message }} className={formStatusClass} role="alert">{/* {formStatus.message} */}</div>
                    </div>
                  </div>



                  {/* <DisplayFormikState {...props} /> */}



              </Form>
            );
          }}
        </Formik>
      }
      </React.Fragment>
    )
}

export default withStyles(styles)(FormStateless)