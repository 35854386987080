import { useStaticQuery, graphql } from 'gatsby'

export default () => {
    const data = useStaticQuery(graphql`
    query {

        allNodeBlog (
            filter: {
                promote: {
                    eq: true
                }
            }
            sort: {
                fields: [created]
                order: DESC
            }
        ) {
            edges {
                node {
                    id
                    drupal_id
                    drupal_internal__nid
                    internal {
                        type
                    }                    

                    title
                    langcode
                    path {
                        alias
                        langcode
                    }
                    created
                    field_summary
                    relationships {
                        bundle: __typename

                        field_image {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 400) {
                                        # ...GatsbyImageSharpFluid_withWebp
                                        ...GatsbyImageSharpFluid_withWebp_noBase64
                                    }
                                    # fluid(maxWidth: 100) {
                                    #     ...GatsbyImageSharpFluid_withWebp
                                    # }
                                    # fixed(width: 400, height: 400) {
                                    #     ...GatsbyImageSharpFixed
                                    # }
                                }
                            }
                        }

                        # @see: https://stackoverflow.com/questions/55032900/gatsby-drupal-8-getting-an-image-when-using-the-media-module-acquia-lightni
                        field_media_image {
                            relationships {
                                field_media_image {
                                    localFile {
                                        childImageSharp {
                                            fluid(maxWidth: 400) {
                                                # ...GatsbyImageSharpFluid_withWebp
                                                ...GatsbyImageSharpFluid_withWebp_noBase64
                                            }
                                            # fluid(maxWidth: 100) {
                                            #     ...GatsbyImageSharpFluid_withWebp
                                            # }
                                            # fixed(width: 400, height: 400) {
                                            #     ...GatsbyImageSharpFixed
                                            # }
                                        }
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }

        # allNodePage (
        #     filter: {
        #         promote: {
        #             eq: true
        #         }
        #     }
        #     sort: {
        #         fields: [created]
        #         order: DESC
        #     }
        # ) {
        #     edges {
        #         node {
        #             id
        #             drupal_id
        #             drupal_internal__nid
        #             internal {
        #                 type
        #             }

        #             title
        #             langcode
        #             path {
        #                 alias
        #                 langcode
        #             }
        #             created
        #             field_summary
        #         }
        #     }
        # }

        # allNodeArticle {
        #     edges {
        #         node {
        #             id
        #             drupal_id
        #             drupal_internal__nid
        #             internal {
        #                 type
        #             }                    
        #             title
        #             langcode
        #             path {
        #                 alias
        #                 langcode
        #             }
        #             created
        #         }
        #     }
        # }

        # allCommerceProduct {
        #     edges {
        #         node {
        #             id
        #             drupal_id
        #             drupal_internal__product_id
        #             internal {
        #                 type
        #             }
        #             title
        #             langcode
        #             path {
        #                 alias
        #                 langcode
        #             }
        #             created
        #         }
        #     }
        # }

    }
    `)

    return data;
}
