// import { Link } from "gatsby"
import React, { useState, useRef } from "react"
import { connect } from 'react-redux'

// s4n
import { useIntl, FormattedHTMLMessage } from "gatsby-plugin-intl"
import Link from './s4n/Link' // extends Link from gatsby-plugin-intl
import loadable from '@loadable/component'
import Preloader from './s4n/Preloader'
import cn from 'classnames'
import UseDrupalBlockContentBasic from './../hooks/drupal/blockContentBasic'
import ImageS4n from "./s4n/image"
//import cssModule from "./../assets/sass/components/Header.module.scss"
// import useBuildTime from './../hooks/useBuildTime'
// ES6
//import Webform from 'hn-react-webform';
import GeneralUtils from './../utils/common'
import Menu from "./s4n/Menu/menu"
import MenuUtils from "./s4n/Menu/utils"

import Logo from './../images/custom/logo.svg'
import LogoWhite from './../images/custom/logo.svg'
// import { ReactComponent as MapPoland } from './../images/custom/POL_location_map.svg'
// import PromotedFrontPage from './s4n/Promoted/FrontPage'
import { simplenewsNewsletter, simplenewsSubscriber } from './s4n/Shop/ext/redux/actions/user'
import Popup from './../components/s4n/Popup'

// import $ from "jquery"


const Footer = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { hasFooterContactForm } = props;

    const refConsent_onPageLoad = useRef(null);
    const refConsent_onInputClick = useRef(null);
    // const refNewsletterInput = useRef(null);
    const refPopupNewsletterInput_onPageLoad = useRef(null);
    const refPopupNewsletterInput_onInputClick = useRef(null);

    const [newsletterEmail, setNewsletterEmail] = useState(null);
    const [newsletterEmailValid, setNewsletterEmailValid] = useState(null);
    const [consentChecked_onPageLoad, setConsentChecked_onPageLoad] = useState(null);
    const [consentChecked_onInputClick, setConsentChecked_onInputClick] = useState(null);
    const [newsletterSubscribed, setNewsletterSubscribed] = useState(null);
    const [popupOpened, setPopupOpened] = useState(false);

    // const nodeContact = MenuUtils.getItemByNodeId(5, intl.locale);
    // const buildTime = useBuildTime();
    // const SvgMapPoland = loadable(() => import(`./svg/map-poland`), {fallback: <Preloader />})
    // const FormHeuresisNewsletter = loadable(() => import(`./../components/forms/webform/heuresis_newsletter`), {fallback: <Preloader />})

    const oClassNames = cn({
        "footer": true,
        // "bg-light": true,
        // "bg-dark": true,
        // "footer-section": hasFooterContactForm,
        // [`alert-${formStatus.class}`]: true,
    });

    const onNewsletterInputChange = (event) => {
        setNewsletterEmail(event.target.value)
        setNewsletterEmailValid(GeneralUtils.validateEmail(event.target.value));
    }

    const onNewsletterSubscription = async (event, context) => {
// console.log(`context: `, context)
        event.preventDefault();
        const authorization = null;
        const simplenews_newsletter = `default`; // name od the default newsletter in Drupal

        // if (refConsent_onPageLoad?.current) {
        //     if (!consentChecked_onPageLoad) {
        //         refConsent_onPageLoad.current.style.color = `red`;
        //         return false;
        //     } else {
        //         refConsent_onPageLoad.current.style.color = ``;
        //     }
        // }
        // if (refConsent_onInputClick?.current) {
        //     if (!consentChecked_onInputClick) {
        //         refConsent_onInputClick.current.style.color = `red`;
        //         return false;
        //     } else {
        //         refConsent_onInputClick.current.style.color = ``;
        //     }
        // }

        // let isConsentChecked = consentChecked;
        // if (`footer` === context) {
        //     isConsentChecked = true;
        // }
        
        let isConsentChecked = null;
        if (`onPageLoad` === context) {
            isConsentChecked = consentChecked_onPageLoad;
        }
        if (`onInputClick` === context) {
            isConsentChecked = consentChecked_onInputClick;
        }

        if (GeneralUtils.validateEmail(newsletterEmail) && isConsentChecked) {
// console.log(`
//     ${authorization}
//     ${newsletterEmail}
//     ${simplenews_newsletter}
// `)
            await props.onSimplenewsSubscriber(authorization, 'POST', null, newsletterEmail, {target_id: simplenews_newsletter, status: 1}) // set status to unconfirmed, requires e-mail link confirmation!
            setNewsletterSubscribed(true);
        } else {
            setNewsletterSubscribed(false);
        }
    }

    const onNewsletterInputClick = (event) => {
        event.preventDefault();
        
        event.target.blur(); // refNewsletterInput?.current?.blur(); // remove focus from element

        setPopupOpened(true);
        
        refPopupNewsletterInput_onPageLoad?.current?.focus(); // add focus on element // THIS DOES NOT CONTAINE INPUT ELEMENT BECAUSE OF CONDITIONAL RENDERING!!!
        refPopupNewsletterInput_onInputClick?.current?.focus(); // add focus on element // THIS DOES NOT CONTAINE INPUT ELEMENT BECAUSE OF CONDITIONAL RENDERING!!!
    }

    return (
        <React.Fragment>
            <UseDrupalBlockContentBasic blockId={66} />

{/* <pre>
  {`consentChecked_onInputClick: `}{JSON.stringify(consentChecked_onInputClick, null, 4)}
</pre> */}

            {/* <!-- footer --> */}
            <footer className={oClassNames}>
                <div className="section">

                    <section className="section _bg-gray newsletter-1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6    d-flex justify-content-center align-items-center">
                                    {/* <h2 className="section-title text-white">Newsletter</h2> */}
                                    <p className="_mb-4 text-white ft-Newsletter_Label">
                                        <FormattedHTMLMessage 
                                            id={`soft4net_newsletter_footer_message`}
                                        />
                                    </p>
                                </div>
                                <div className="col-lg-6    d-flex justify-content-center align-items-center">
                                    {newsletterSubscribed ?
                                        <p className="text-success">{ t({ id: `soft4net_newsletter_subscribed` }) }</p>
                                        :
                                        <form action="#" className={`d-flex flex-column flex-sm-row `}>
                                            <input 
                                                onChange={onNewsletterInputChange}
                                                id="mail" type="email" placeholder="Adres e-mail" value={newsletterEmail}
                                                className={`form-control ft-Newsletter_Input ${newsletterEmailValid ? `is-valid` : `is-invalid`}`}
                                                onClick={onNewsletterInputClick}
                                            />
                                            <button 
                                                onClick={onNewsletterInputClick}
                                                type="submit" value="send" className="btn btn-primary"
                                            >{ t({ id: `soft4net_newsletter_subscribe` }) }</button>
                                        </form>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>

                    <Popup 
                        className={`newsletter onInputClick`} 
                        forceOpen={popupOpened} 
                        setPopupOpened={setPopupOpened} 
                        ref={refPopupNewsletterInput_onInputClick} 
                    >
                        <section className="newsletter-1">
                            <div className="container px-0">
                                <div className="row no-gutters">
                                    <div className="col-xl-5 d-none d-xl-block">
                                        <ImageS4n src={`custom/page/home/blog.jpg`} alt={``} _className="img-fluid rounded w-100" _maxWidth="300px" _maxHeight="5rem" />
                                    </div>
                                    <div className="col col-xl-7">

                                        <div className="wrapper">
                                            <div className="row">
                                                <div className="col benefits">
                                                    <FormattedHTMLMessage 
                                                        id={`soft4net_newsletter_popup_message`}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    {/* {newsletterSubscribed ?
                                                        <p className="text-success">{ t({ id: `soft4net_newsletter_subscribed` }) }</p>
                                                        : */}
                                                        <form action="#" className="d-flex flex-column _flex-sm-row">
                                                            <div className={`d-flex flex-row e-mail`}>
                                                                <input 
                                                                    
                                                                    ref={refPopupNewsletterInput_onInputClick}
                                                                    // autoFocus={true} onBlur={({ target }) => target.focus()}

                                                                    onChange={onNewsletterInputChange}
                                                                    id="mailPopup_onInputClick" type="email" placeholder="Adres e-mail" value={newsletterEmail}
                                                                    className={`form-control ft-Newsletter_Input ${newsletterEmailValid ? `is-valid` : `is-invalid`}`}
                                                                />
                                                                <button onClick={(e) => {
                                                                    onNewsletterSubscription(e, `onInputClick`); 
                                                                    setConsentChecked_onInputClick(false);
                                                                }} type="submit" value="send" className="btn btn-primary">{ t({ id: `soft4net_newsletter_subscribe` }) }</button>
                                                            </div>
                                                            <div className="form-check  consent">
                                                                <input 
                                                                    className="form-check-input" type="checkbox" value="" id="popup-newsletter-consent_onInputClick" 
                                                                    // onClick={() => setConsentChecked(!consentChecked)}
                                                                    onClick={() => setConsentChecked_onInputClick(!(!!consentChecked_onInputClick))}
                                                                />
                                                                <label 
                                                                    className="form-check-label" for="popup-newsletter-consent_onInputClick"
                                                                    ref={refConsent_onInputClick}
                                                                    style={{color: consentChecked_onInputClick === true ? `` : (consentChecked_onInputClick === null ? `` : `red`)}}
                                                                >
                                                                    { t({ id: `soft4net_newsletter_popup_consent` }) }
                                                                </label>
                                                            </div>
                                                        </form>
                                                    {/* } */}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>
                    </Popup>

                    {1 === Number(props.commerceStoreOnlineData?.field_popup_newsletter_enabled) &&
                        <Popup 
                            className={`newsletter onPageLoad`} 
                            delay={props.commerceStoreOnlineData?.field_popup_newsletter_delay}
                        >
                            <section className="newsletter-1">
                                <div className="container px-0">
                                    <div className="row no-gutters">
                                        <div className="col-xl-5 d-none d-xl-block">
                                            <ImageS4n src={`custom/page/home/blog.jpg`} alt={``} _className="img-fluid rounded w-100" _maxWidth="300px" _maxHeight="5rem" />
                                        </div>
                                        <div className="col col-xl-7">

                                            <div className="wrapper">
                                                <div className="row">
                                                    <div className="col benefits">
                                                        <FormattedHTMLMessage 
                                                            id={`soft4net_newsletter_popup_message`}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        {/* {newsletterSubscribed ?
                                                            <p className="text-success">{ t({ id: `soft4net_newsletter_subscribed` }) }</p>
                                                            : */}
                                                            <form action="#" className="d-flex flex-column _flex-sm-row">
                                                                <div className={`d-flex flex-row e-mail`}>
                                                                    <input 
                                                                        
                                                                        ref={refPopupNewsletterInput_onPageLoad}
                                                                        // autoFocus={true} onBlur={({ target }) => target.focus()}

                                                                        onChange={onNewsletterInputChange}
                                                                        id="mailPopup_onPageLoad" type="email" placeholder="Adres e-mail" value={newsletterEmail}
                                                                        className={`form-control ft-Newsletter_Input ${newsletterEmailValid ? `is-valid` : `is-invalid`}`}
                                                                    />
                                                                    <button onClick={(e) => {
                                                                        onNewsletterSubscription(e, `onPageLoad`); 
                                                                        setConsentChecked_onPageLoad(false);
                                                                    }} type="submit" value="send" className="btn btn-primary">{ t({ id: `soft4net_newsletter_subscribe` }) }</button>
                                                                </div>
                                                                <div className="form-check  consent">
                                                                    <input 
                                                                        className="form-check-input" type="checkbox" value="" id="popup-newsletter-consent_onPageLoad" 
                                                                        // onClick={() => setConsentChecked(!consentChecked)}
                                                                        onClick={() => setConsentChecked_onPageLoad(!consentChecked_onPageLoad)}
                                                                    />
                                                                    <label 
                                                                        className="form-check-label" for="popup-newsletter-consent_onPageLoad"
                                                                        ref={refConsent_onPageLoad}
                                                                        // style={{color: consentChecked_onPageLoad !== null && !consentChecked_onPageLoad ? `red` : ``}}
                                                                        style={{color: !consentChecked_onPageLoad ? (consentChecked_onPageLoad === null ? `` : `red`) : ``}}
                                                                    >
                                                                        { t({ id: `soft4net_newsletter_popup_consent` }) }
                                                                    </label>
                                                                </div>
                                                            </form>
                                                        {/* } */}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </section>
                        </Popup>
                    }

                    <UseDrupalBlockContentBasic blockId={1} />

                </div>

            </footer>
            {/* <!-- /footer --> */}

        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    isHighContrastMode: state.highContrast.isHighContrastMode,
    // user: state.shopReducers.user,
})

const mapDispatchToProps = dispatch => ({
    // Get all newsletters defined in the CMS and details about them as an array
    // onSimplenewsNewsletter: (authorization, simplenews_newsletter) => dispatch(simplenewsNewsletter(authorization, simplenews_newsletter)),

    // Get information wether user is subscribed to a specific newsletter or it can PATCH sunscription to certain newsletetr switch ON / OFF
    onSimplenewsSubscriber: (authorization, method, status, mail, subscriptions) => dispatch(simplenewsSubscriber(authorization, method, status, mail, subscriptions)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Footer);